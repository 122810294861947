/** @format */

import React, { useState } from "react";
import { BiRadioCircle } from "react-icons/bi";
import { BsFillBagFill } from "react-icons/bs";
import { HiUsers } from "react-icons/hi";
import { MdModeEditOutline } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { Carousel } from "react-responsive-carousel";
import { addVehicleCurruntSelected, isSelect, removeSelect, removeVehicleCurruntSelected } from "./VehicleSlice";

const SelectVehicle = (props) => {
  
    const { curruntVehicle, Select } = useSelector((state) => {
      const curruntVehicle = state.vehicle.curruntVehicle;
      const Select = state.vehicle.isSelect;
      return {
        curruntVehicle,
        Select,
      };
    });
  const [details, setDetails] = useState(null);
  const dispatch = useDispatch();

  let Edit = () => {
    setDetails(null);
    window.scrollTo(0, 720);
  };

  let Book = (e) => {
    setDetails(e);
    dispatch(addVehicleCurruntSelected(e));
    dispatch(isSelect())
  };


  return (
    <>
      <div className="container">
        <div className="row ride-info">
          <span className="panel-title select-btn ml-2">
            <span
              style={{
                color: "rgb(15 101 228 / 87%)",
                cursor: "pointer",
                "font-size": "14px",
                "font-weight": "500",
              }}>
              <BiRadioCircle size={20} /> Step 2: Select Vehicle
            </span>
            {Select && (
              <span
                className="ors-btn-login"
                id="holderLoginForm"
                style={{
                  color: "rgb(74, 74, 74)",
                  cursor: "pointer",
                  fontSize: "14px",
                  fontWeight: "600",
                }}
                onClick={() => {
                  Edit();
                  dispatch(removeVehicleCurruntSelected())
                  dispatch(removeSelect())
                }}>
                <MdModeEditOutline size={17} /> Edit
              </span>
            )}
          </span>
        </div>
      </div>

      {Select ? (
        <div className="container mt-5">
          <div className="row">
            <div className="col-md-3">
              <Carousel>
                {curruntVehicle &&
                  curruntVehicle.car_pics.map((el, i) => (
                    <div style={{ height: "145px" }}>
                      <img
                        src={`${process.env.REACT_APP_BASE_URL}/Cars/${el}`}
                      />
                    </div>
                  ))}
              </Carousel>
            </div>

            <div className="col-md-2">
              <div class="vehicle-grid-item-specs2  align-item-center">
                <div class="input-group">
                  <span class="input-group-addon">
                    <HiUsers />
                  </span>
                  <span class="input-group-addon">
                    {curruntVehicle && curruntVehicle.passenger}
                  </span>
                </div>
                <br />
                <div class="input-group">
                  <div class="input-group-addon">
                    <BsFillBagFill />
                  </div>
                  <span class="input-group-addon">
                    {curruntVehicle && curruntVehicle.luggage}
                  </span>
                </div>
              </div>
            </div>
            <div className="col-md-7">
              <h4> {curruntVehicle && curruntVehicle.car_name}</h4>
              <p>
                Mercedes, Cadillac, Lincoln ( Late Model ) OR Similar Free 45
                minutes wait time for airport pickups, 5 mins for all others
                Complimentary bottled water **Please Be Prices Are Subject To
                Change Based Upon Travel Time**
              </p>
            </div>
          </div>
        </div>
      ) : (
        <div>
          <div class="row cars-listing mt-3">
            {props.cars &&
              props.cars.map((e, i) => (
                <div className="col-md-3 text-center the-cars">
                  <div className="mt-3">
                    <h5>{e.car_name}</h5>
                    <Carousel>
                      {e.car_pics.map((el, i) => (
                        <div style={{ height: "145px" }}>
                          <img
                            src={`${process.env.REACT_APP_BASE_URL}/Cars/${el}`}
                          />
                        </div>
                      ))}
                    </Carousel>
                    <div class="vehicle-grid-item-specs align-item-center">
                      <div class="input-group">
                        <span class="input-group-addon">
                          <HiUsers />
                        </span>
                        <span class="input-group-addon">{e.passenger}</span>
                      </div>
                      <div class="input-group">
                        <span class="input-group-addon">
                          <BsFillBagFill />
                        </span>
                        <span class="input-group-addon">{e.luggage}</span>
                      </div>
                    </div>
                    <div
                      class="vehicle-grid-item-price-numb mt-3"
                      style={{ "font-size": "23px", color: "#003fbf" }}>
                      <strong> ${parseInt(e.fare)} </strong>
                    </div>
                    <div className="mt-3">
                      <button className="book-btn" onClick={() => Book(e)}>
                        Book Now
                      </button>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      )}
    </>
  );
};

export default SelectVehicle;
