/** @format */

import React from "react";
import MainFooter from "../components/MainFooter";
import { MainHeader } from "../components/MainHeader";

export const Fleet = () => {
  return (
    <>
      <MainHeader />

      <div id="marine-content-inner">
        <section id="main-content">
          <div class="container">
            <a href="https://www.continentalclubcar.com/reviews.html">
              <img
                class="rev_btn"
                src="/images/customer-reviews.png"
                width="100"
                height="39"
              />
            </a>
            <div class="page-heading style3 wrapper border-bottom ">
              <div class="row">
                <div class="col-lg-6 col-md-6 col-sm-6">
                  <h1>Bay Area Limousine Fleet</h1>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6">
                  <p class="breadcrumbs">
                    <span typeof="v:Breadcrumb">
                      <a href="/" property="v:title" rel="v:url">
                        Home
                      </a>
                    </span>
                    <span class="delimiter">|</span>
                    <span class="current">Fleet Gallery</span>
                  </p>
                </div>
              </div>
            </div>
            <div class="row row-fluid ">
              <div class="col-sm-12 column column_container">
                <div class="align-center">
                  <div class="row projects-container" id="MixItUp4F7D79">
                    <div
                      class="col-lg-4 col-md-4 col-sm-4 mix category-print"
                      style={{ display: "block" }}>
                      <div class="project">
                        <div class="project-image">
                          <img alt="####" src="/images/vehicle1.jpeg" />
                          <div class="project-hover">
                            {" "}
                            <a
                              class="search-icon prettyPhoto"
                              href="/images/vehicle1-int.jpeg"></a>{" "}
                          </div>
                        </div>
                        <div class="project-meta">
                          <h4>Mercedes</h4>
                          <span class="project-category">S550</span>
                        </div>
                      </div>
                    </div>

                    <div
                      class="col-lg-4 col-md-4 col-sm-4 mix category-print"
                      style={{ display: "block" }}>
                      <div class="project">
                        <div class="project-image">
                          <img alt="####" src="/images/fleet.jpeg" />
                          <div class="project-hover">
                            {" "}
                            <a
                              class="search-icon prettyPhoto"
                              href="/images/fleetlncon.png"></a>{" "}
                          </div>
                        </div>
                        <div class="project-meta">
                          <h4>Lincoln</h4>
                          <span class="project-category">Navigator</span>
                        </div>
                      </div>
                    </div>

                    <div
                      class="col-lg-4 col-md-4 col-sm-4 mix category-print"
                      style={{ display: "block" }}>
                      <div class="project">
                        <div class="project-image">
                          <img alt="####" src="/images/vehicle3.jpeg" />
                          <div class="project-hover">
                            {" "}
                            <a
                              class="search-icon prettyPhoto"
                              href="/images/vehicle3-int.jpeg"></a>{" "}
                          </div>
                        </div>
                        <div class="project-meta">
                          <h4>Cadillac</h4>
                          <span class="project-category">XTS</span>
                        </div>
                      </div>
                    </div>

                    <div class="clearfix marine-header2-clearfix60"></div>

                    <div
                      class="col-lg-4 col-md-4 col-sm-4 mix category-print"
                      style={{ display: "block" }}>
                      <div class="project">
                        <div class="project-image">
                          <img alt="####" src="/images/vehicle4.jpeg" />
                          <div class="project-hover">
                            {" "}
                            <a
                              class="search-icon prettyPhoto"
                              href="/images/vehicle4-int.jpeg"></a>{" "}
                          </div>
                        </div>
                        <div class="project-meta">
                          <h4>Lincoln</h4>
                          <span class="project-category">Continental</span>
                        </div>
                      </div>
                    </div>

                    <div
                      class="col-lg-4 col-md-4 col-sm-4 mix category-print"
                      style={{ display: "block" }}>
                      <div class="project">
                        <div class="project-image">
                          <img alt="####" src="/images/cadillac.jpeg" />
                          <div class="project-hover">
                            {" "}
                            <a
                              class="search-icon prettyPhoto"
                              href="/images/vehicle6-int.jpeg"></a>{" "}
                          </div>
                        </div>
                        <div class="project-meta">
                          <h4>Cadillac</h4>
                          <span class="project-category">Escalade</span>
                        </div>
                      </div>
                    </div>

                    <div
                      class="col-lg-4 col-md-4 col-sm-4 mix category-print"
                      style={{ display: "block" }}>
                      <div class="project">
                        <div class="project-image">
                          <img alt="####" src="/images/vehicle9.jpeg" />
                          <div class="project-hover">
                            {" "}
                            <a
                              class="search-icon prettyPhoto"
                              href="/images/vehicle9-int.jpeg"></a>{" "}
                          </div>
                        </div>
                        <div class="project-meta">
                          <h4>Mercedes</h4>
                          <span class="project-category">Sprinter</span>
                        </div>
                      </div>
                    </div>

                    <div class="clearfix marine-header2-clearfix60"></div>
                    <div class="row row-fluid padding-bottom20">
                      <h2 align="center" style={{ fontWeight: "700" }}>
                        Our{" "}
                        <img
                          src="/images/five-stars.png"
                          width="200"
                          height="118"
                        />{" "}
                        Reviews &amp; Testimonials
                      </h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <MainFooter />
    </>
  );
};
