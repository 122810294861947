/** @format */

import React from "react";
import { NavLink } from "react-router-dom";
import MainFooter from "../../components/MainFooter";
import { MainHeader } from "../../components/MainHeader";

const SouthBayCarService = () => {
  return (
    <>
      <MainHeader />
      <section id="main-content">
        <div className="container">
          <NavLink to="/reviews">
            <img
              className="rev_btn"
              src="/images/customer-reviews.png"
              width="100"
              height="39"
            />
          </NavLink>
          <div className="page-heading style3 wrapper border-bottom ">
            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-6">
                <h1>South Bay Car Service</h1>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-6">
                <p className="breadcrumbs">
                  <span typeof="v:Breadcrumb">
                    <NavLink to="/" property="v:title" rel="v:url">
                      Home
                    </NavLink>
                  </span>
                  <span className="delimiter">|</span>
                  <span className="current">Services</span>
                </p>
              </div>
            </div>
          </div>
          <div className="row padding-top60">
            <section className="col-lg-12 col-md-12 col-sm-12">
              <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="flexslider flexslider-thumbnail-gallery ">
                    <ul className="slides">
                      <li
                        className="flex-active-slide"
                        style={{
                          width: "100%",
                          float: "left",
                          marginRight: "-100%",
                          position: "relative",
                          opacity: 1,
                          display: "block",
                          zIndex: 2,
                        }}>
                        <img
                          src="/images/bay-area-car-services.jpeg"
                          draggable="false"
                          alt=""
                        />
                      </li>
                    </ul>
                    <ol className="flex-control-nav flex-control-thumbs"></ol>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <h2
                    className="special-text wow animated fadeInDown services-special-h3 animated"
                    data-animation="fadeInDown"
                    style={{
                      visibility: "visible",
                      animationName: "fadeInDown",
                    }}>
                    Your South Bay Car Rental Leader
                  </h2>
                  <p>
                    Besides the usual prom and wedding rentals, the Northern
                    California South Bayoffers countless opportunities to turn
                    mere transportation into memorable experiences with South
                    Bay Car Service. Imagine kicking off that well-deserved
                    dream vacation with an elegant limousine. You'll start to
                    relax and enjoy the experience from the moment you leave
                    your home, with no worries about traffic and airport
                    congestion.
                  </p>
                  <p>
                    But why wait? Our affordable limo rentals mean that you can
                    transform local celebrations as well. Your South Bay Car
                    Service can cover anywhere in the region, including shopping
                    and sightseeing and on into wine country. Our South Bay Car
                    Service is perfect for sightseeing tours. Your car services
                    private chauffeur takes care of all the driving while you
                    enjoy the wineries and vineyard scenery from a luxurious
                    limousine with all the amenities you need for enjoying your
                    new-found wines and delectable snacks.
                  </p>
                  <p>
                    Our responsible and friendly drivers will take care of
                    everything. With South Bay Car Service you'll treat your
                    guests to a stylish and ever-so-comfortable ride with a
                    great sound system, special lighting, and bar to enjoy all
                    along the way. Plus there's no hiking from distant parking
                    to the main attractions and events. A South Bay Car Service
                    makes that all happen with just one phone call, and at very
                    affordable prices.
                  </p>
                  <NavLink
                    className="button biggest"
                    to="/club-limousine-reservations"
                    target="_self">
                    BOOK NOW
                  </NavLink>
                </div>
              </div>
              <div className="clearfix clearfix-height30"></div>
              <div className="row row-fluid padding-bottom20">
                <h2 align="center" style={{ fontWeight: "700" }}>
                  Our{" "}
                  <img src="/images/five-stars.png" width="200" height="118" />{" "}
                  Reviews &amp; Testimonials
                </h2>
              </div>
              <script
                src="https://apps.elfsight.com/p/platform.js"
                defer=""
                type="text/javascript"></script>
              <div
                className="full_bg full-width-bg marine-header2-full_bg3"
                data-animation="">
                <h3
                  className="special-text wow animated fadeInDown marine-header2-h3 animated"
                  data-animation="fadeInDown"
                  style={{
                    visibility: "visible",
                    animationName: "fadeInDown",
                  }}>
                  South Bay Luxury Transportation
                </h3>
                <div
                  className="special-text wow animated fadeInLeft marine-header2-special-text1 animated"
                  data-animation="fadeInLeft"
                  style={{
                    visibility: "visible",
                    animationName: "fadeInDown",
                  }}>
                  For business and personal transportation, our South Bay car
                  services get you where you need to be, right on schedule,
                  without stress, distractions, or parking delays. Choose a
                  luxury sedan, town car, or SUV and escape your daily pressures
                  with the comfort and amenities of our South Bay car transfer
                  service.{" "}
                  <img
                    alt=""
                    className="aligncenter dont_scale wow animated fadeInDown animated"
                    data-animation="fadeInDown"
                    src="/images/la-limo-rentals.png"
                    style={{
                      visibility: "visible",
                      animationName: "fadeInDown",
                    }}
                  />
                  <div className="clearfix"></div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </section>
      <MainFooter />
    </>
  );
};

export default SouthBayCarService;
