import React from 'react'
import MainFooter from '../../../components/MainFooter';
import { MainHeader } from '../../../components/MainHeader';
import { NavLink } from "react-router-dom";

export const NapaWineTour = () => {
  return (
    <>
      <MainHeader />
      <div id="marine-content-inner">
        <section id="main-content">
          <div className="container">
            <NavLink to="/reviews">
              <img
                className="rev_btn"
                src="/images/customer-reviews.png"
                width="100"
                height="39"
              />
            </NavLink>
            <div className="page-heading style3 wrapper border-bottom ">
              <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-6">
                  <h1>Napa Wine Tours</h1>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6">
                  <p className="breadcrumbs">
                    <span typeof="v:Breadcrumb">
                      <NavLink to="/" property="v:title" rel="v:url">
                        Home
                      </NavLink>
                    </span>
                    <span className="delimiter">|</span>
                    <span className="current">Services</span>
                  </p>
                </div>
              </div>
            </div>
            <div className="row padding-top60">
              <section className="col-lg-12 col-md-12 col-sm-12">
                <div className="row">
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="flexslider flexslider-thumbnail-gallery ">
                      <ul className="slides">
                        <li
                          className="flex-active-slide"
                          style={{
                            width: "100%",
                            float: "left",
                            marginRight: "-100%",
                            position: "relative",
                            opacity: 1,
                            display: "block",
                            zIndex: "2",
                          }}>
                          <img
                            alt=""
                            src="/images/bay-area-wine-tours.jpeg"
                            draggable="false"
                          />
                        </li>
                      </ul>
                      <ol className="flex-control-nav flex-control-thumbs"></ol>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <h2
                      className="special-text wow animated fadeInDown services-special-h3 animated"
                      data-animation="fadeInDown"
                      style={{
                        visibility: "visible",
                        animationName: "fadeInDown",
                      }}>
                      Napa Vineyards Transportation
                    </h2>
                    <p>
                      An elegant limousine is the very best transportation for
                      wine tours in the bay area. Your polite and skilled
                      chauffeur takes care of all the driving, so you can do as
                      much wine tasting as you like. But the benefits of Napa
                      Valley limo tours go way beyond that. You'll travel in
                      style in luxury, making the day a memorable celebration as
                      you enjoy this part of wine country's many fine
                      restaurants and countless wineries. With one call to the
                      Napa Valley group transportation experts at CONTINENTAL CLUB CARe
                      Luxury Transportation Service you can arrange one-day Napa
                      Valley limo tours or even overnight expeditions.
                    </p>
                    <h2
                      className="special-text wow animated fadeInDown services-special-h3 animated"
                      data-animation="fadeInDown"
                      style={{
                        visibility: "visible",
                        animationName: "fadeInDown",
                      }}>
                      Wine Tour Limousine
                    </h2>
                    <p>
                      With limousine wine tours bay area you and your guests can
                      enjoy classical music, or whatever style of entertainment
                      you prefer, on a top-quality sound system. Plus you'll
                      have everything you need to chill and share some of your
                      recent purchases.
                    </p>
                    <p>
                      Our services are the simple answer to Napa Valley group
                      transportation for gatherings of up to 10 people, with
                      everyone traveling together. CONTINENTAL CLUB CAR's Napa Valley
                      limo tours combine experienced scheduling, flawless
                      vehicles, and expert drivers. Call us for all all of your
                      Napa vineyards transportation needs.
                    </p>
                    <NavLink
                      className="button biggest"
                      to="/club-limousine-reservations"
                      target="_self">
                      BOOK NOW
                    </NavLink>
                  </div>
                </div>
                <div className="clearfix clearfix-height30"></div>
                <div className="row row-fluid padding-bottom20">
                  <h2 align="center" style={{ fontWeight: "700" }}>
                    Our{" "}
                    <img
                      src="/images/five-stars.png"
                      width="200"
                      height="118"
                    />{" "}
                    Reviews &amp; Testimonials
                  </h2>
                </div>
                <div
                  className="full_bg full-width-bg marine-header2-full_bg3"
                  data-animation="">
                  <h3
                    className="special-text wow animated fadeInDown marine-header2-h3"
                    data-animation="fadeInDown"
                    style={{ visibility: "visible", animationName: "none" }}>
                    Bay Area Luxury Transportation
                  </h3>
                  <div
                    className="special-text wow animated fadeInLeft marine-header2-special-text1"
                    data-animation="fadeInLeft"
                    style={{ visibility: "visible", animationName: "none" }}>
                    Black Car's East Bay limo deals are perfect for corporate
                    travel around town, including dining, event, and
                    entertainment venues.
                    <br />
                    Call CONTINENTAL CLUB CAR Luxury Transportation for all Your east
                    Bay area limo service needs.{" "}
                    <img
                      alt=""
                      className="aligncenter dont_scale wow animated fadeInDown"
                      data-animation="fadeInDown"
                      src="/images/la-limo-rentals.png"
                      style={{ visibility: "visible", animationName: "none" }}
                    />
                    <div className="clearfix"></div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </section>
      </div>
      <MainFooter />
    </>
  );
}

