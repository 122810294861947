import React from 'react'
import MainFooter from '../../components/MainFooter';
import { MainHeader } from '../../components/MainHeader';
import { NavLink } from "react-router-dom";
export const BayAreaLimousine = () => {
  return (
    <>
      <MainHeader />
      <div id="marine-content-inner">
        <section id="main-content">
          <div className="container">
            <NavLink to="/reviews">
              <img
                className="rev_btn"
                src="/images/customer-reviews.png"
                width="100"
                height="39"
              />
            </NavLink>
            <div className="page-heading style3 wrapper border-bottom ">
              <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-6">
                  <h1>Bay Area Limousine</h1>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6">
                  <p className="breadcrumbs">
                    <span typeof="v:Breadcrumb">
                      <NavLink to="/" property="v:title" rel="v:url">
                        Home
                      </NavLink>
                    </span>
                    <span className="delimiter">|</span>
                    <span className="current">Services</span>
                  </p>
                </div>
              </div>
            </div>
            <div className="row padding-top60">
              <section className="col-lg-12 col-md-12 col-sm-12">
                <div className="row">
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="flexslider flexslider-thumbnail-gallery ">
                      <ul className="slides">
                        <li
                          className="flex-active-slide"
                          style={{
                            width: "100%",
                            float: "left",
                            marginRight: "-100%",
                            position: "relative",
                            opacity: 1,
                            display: "block",
                            zIndex: "2",
                          }}>
                          <img
                            alt=""
                            src="/images/bay-area-limos.jpeg"
                            draggable="false"
                          />
                        </li>
                      </ul>
                      <ol className="flex-control-nav flex-control-thumbs"></ol>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <h2
                      className="special-text wow animated fadeInDown services-special-h3 animated"
                      data-animation="fadeInDown"
                      style={{
                        visibility: "visible",
                        animationName: "fadeInDown",
                      }}>
                      Bay Area Limousine Services
                    </h2>
                    <p>
                      Whether for impressing a business client, celebrating a
                      special event, or just time-saving transportation our
                      affordable limo rental services don't skimp on quality,
                      luxury, or amenities. There's no compromise in our SF limo
                      deals: you'll have a stylish perfectly maintained
                      limousine and an experienced, well-trained, and
                      professionally polite driver. Whatever you're looking for
                      in San Francisco Limo Service we have it all, from
                      corporate limo service to party limos.
                    </p>
                    <p>
                      Let a San Francisco limo provide you with reliable,
                      comfortable, and down right luxurious transportation
                      around The City and to and from regional attractions. Our
                      SF limo deals make it all affordable. With a track record
                      spanning many years, we're the trusted choice of countless
                      individual and corporate clients. Choose from our fleet of
                      elegant limousines, town cars, and SUVs and then relax
                      with confidence, knowing that our bay area limo will take
                      care of everything. You'll always be on time for your
                      grand entrance or meeting that important prospective
                      client.
                    </p>
                    <p>
                      Black Car SF limo deals are perfect for avoiding airport
                      traffic snarls and parking delays, with on-time drop offs
                      and pick-ups. Executives choose us for our dependable
                      transportation around town, keeping them on schedule and
                      focused on business throughout the day. We're also happy
                      to charter bay area limo shuttles for hotels, tours,
                      theaters, conferences and other special events.
                    </p>
                    <NavLink
                      className="button biggest"
                      to="/club-limousine-reservations"
                      target="_self">
                      BOOK NOW
                    </NavLink>
                  </div>
                </div>
                <div className="clearfix clearfix-height30"></div>
                <div className="row row-fluid padding-bottom20">
                  <h2 align="center" style={{ fontWeight: "700" }}>
                    Our{" "}
                    <img
                      src="/images/five-stars.png"
                      width="200"
                      height="118"
                    />{" "}
                    Reviews &amp; Testimonials
                  </h2>
                </div>
                <div
                  className="full_bg full-width-bg marine-header2-full_bg3"
                  data-animation="">
                  <h3
                    className="special-text wow animated fadeInDown marine-header2-h3"
                    data-animation="fadeInDown"
                    style={{ visibility: "visible", animationName: "none" }}>
                    Bay Area Luxury Transportation
                  </h3>
                  <div
                    className="special-text wow animated fadeInLeft marine-header2-special-text1"
                    data-animation="fadeInLeft"
                    style={{ visibility: "visible", animationName: "none" }}>
                    Black Car's East Bay limo deals are perfect for corporate
                    travel around town, including dining, event, and
                    entertainment venues.
                    <br />
                    Call CONTINENTAL CLUB CAR Luxury Transportation for all Your east
                    Bay area limo service needs.{" "}
                    <img
                      alt=""
                      className="aligncenter dont_scale wow animated fadeInDown"
                      data-animation="fadeInDown"
                      src="/images/la-limo-rentals.png"
                      style={{ visibility: "visible", animationName: "none" }}
                    />
                    <div className="clearfix"></div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </section>
      </div>
      <MainFooter />
    </>
  );
}