/** @format */

import React from "react";
import MainFooter from "../../components/MainFooter";
import { MainHeader } from "../../components/MainHeader";
import { NavLink } from "react-router-dom";
export const BayAreaCorporateTransportation = () => {
  return (
    <>
      <MainHeader />
      <section id="main-content">
        <div className="container">
          <NavLink to="/reviews">
            <img
              className="rev_btn"
              src="/images/customer-reviews.png"
              width="100"
              height="39"
            />
          </NavLink>
          <div className="page-heading style3 wrapper border-bottom">
            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-6">
                <h1>NYC Corporate Transportation</h1>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-6">
                <p className="breadcrumbs">
                  <span typeof="v:Breadcrumb">
                    <NavLink to="/" property="v:title" rel="v:url">
                      Home
                    </NavLink>
                  </span>
                  <span className="delimiter">|</span>
                  <span className="current">Services</span>
                </p>
              </div>
            </div>
          </div>
          <div className="row padding-top60">
            <section className="col-lg-12 col-md-12 col-sm-12">
              <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="flexslider flexslider-thumbnail-gallery ">
                    <ul className="slides">
                      <li
                        className="flex-active-slide"
                        style={{
                          width: "100%",
                          float: "left",
                          marginRight: "-100%",
                          position: "relative",
                          opacity: 1,
                          display: "block",
                          zIndex: 2,
                        }}>
                        <img
                          src="/images/bay-area-corporate-transportation.jpeg"
                          draggable="false"
                          alt=""
                        />
                      </li>
                    </ul>
                    <ol className="flex-control-nav flex-control-thumbs"></ol>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <h2
                    className="special-text wow animated fadeInDown services-special-h3 animated"
                    data-animation="fadeInDown"
                    style={{
                      visibility: "visible",
                      animationName: "fadeInDown",
                    }}>
                    Continental Car Club Corporate Car Service
                  </h2>
                  <p>
                    We provide flawless corporate black limousines that always
                    make the right impression together with skilled chauffeurs
                    and staff experienced in meeting the demands of executive
                    transportation. We provide excellence in NYC Black Car
                    Service at very competitive rates.
                  </p>
                  <p>
                    A sleek black corporate limousine adds elegance to
                    entertaining business partners and prospective clients,
                    whether that's a magnificent meal at one of The City's top
                    restaurants or taking in a show or the night lights.
                    Continental car club’s corporate black car service with
                    plush interior, the full amenities, and a courteous and
                    respectful driver demonstrates that you value your guests
                    and care about quality.
                  </p>
                  <p>
                    Our corporate limo service is perfect when you have
                    important out of town visitors. A uniformed chauffeur will
                    meet them at the airport's baggage claim, escort them to a
                    stylish nyc corporate limousine, then whisk them past
                    ground transportation's confusion and delay and on to their
                    hotel or directly to your office or conference. For their
                    departures our experienced nyc corporate car service
                    staff and drivers assure that they'll arrive at the right
                    terminal with time to spare. Our company's corporate black
                    car service fleet offers a range of choices, including
                    Lincoln Town Car L Series and MKT sedans, Mercedes S550 and
                    BMW 7 Series vehicles, Chevrolet Suburban and Lincoln
                    Navigator luxury SUVs, and 10-passenger stretch limousines.
                  </p>
                  <NavLink
                    className="button biggest"
                    to="/club-limousine-reservations"
                    target="_self">
                    BOOK NOW
                  </NavLink>
                </div>
              </div>
              <div className="clearfix clearfix-height30"></div>
              <div className="row row-fluid padding-bottom20">
                <h2 align="center" style={{ fontWeight: "700" }}>
                  Our{" "}
                  <img src="/images/five-stars.png" width="200" height="118" />{" "}
                  Reviews &amp; Testimonials
                </h2>
              </div>
              <script
                src="https://apps.elfsight.com/p/platform.js"
                defer=""
                type="text/javascript"></script>
              <div
                className="full_bg full-width-bg marine-header2-full_bg3"
                data-animation="">
                <h3
                  className="special-text wow animated fadeInDown marine-header2-h3 animated"
                  data-animation="fadeInDown"
                  style={{
                    visibility: "visible",
                    animationName: "fadeInDown",
                  }}>
                  NYC Corporate Luxury Transportation
                </h3>
                <div
                  className="special-text wow animated fadeInLeft marine-header2-special-text1 animated"
                  data-animation="fadeInLeft"
                  style={{
                    visibility: "visible",
                    animationName: "fadeInDown",
                  }}>
                  For business and personal transportation, our nyc car
                  services get you where you need to be, right on schedule,
                  without stress, distractions, or parking delays. Choose a
                  luxury sedan, town car, or SUV and escape your daily pressures
                  with the comfort and amenities of our nyc car transfer
                  service.{" "}
                  <img
                    alt=""
                    className="aligncenter dont_scale wow animated fadeInDown animated"
                    data-animation="fadeInDown"
                    src="/images/la-limo-rentals.png"
                    style={{
                      visibility: "visible",
                      animationName: "fadeInDown",
                    }}
                  />
                  <div className="clearfix"></div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </section>
      <MainFooter />
    </>
  );
};







  // <div id="marine-content-inner">
  //   <section id="main-content">
  //     <div className="container">
  //       <NavLink to="https://www.continentalclubcar.com/reviews.html">
  //         <img
  //           className="rev_btn"
  //           src="/images/customer-reviews.png"
  //           width="100"
  //           height="39"
  //         />
  //       </NavLink>
  //       <div className="page-heading style3 wrapper border-bottom ">
  //         <div className="row">
  //           <div className="col-lg-6 col-md-6 col-sm-6">
  //             <h1>Bay Area Corporate Transportation</h1>
  //           </div>
  //           <div className="col-lg-6 col-md-6 col-sm-6">
  //             <p className="breadcrumbs">
  //               <span typeof="v:Breadcrumb">
  //                 <NavLink to="/" property="v:title" rel="v:url">
  //                   Home
  //                 </NavLink>
  //               </span>
  //               <span className="delimiter">|</span>
  //               <span className="current">Services</span>
  //             </p>
  //           </div>
  //         </div>
  //       </div>

  //       <div
  //         className="full_bg full-width-bg marine-header2-full_bg3"
  //         data-animation="">
  //         <h3
  //           className="special-text wow animated fadeInDown marine-header2-h3"
  //           data-animation="fadeInDown"
  //           style={{ visibility: "hidden", animationName: "none" }}>
  //           Bay Area Luxury Transportation
  //         </h3>
  //         <div
  //           className="special-text wow animated fadeInLeft marine-header2-special-text1"
  //           data-animation="fadeInLeft"
  //           style={{ visibility: "hidden", animationName: "none" }}>
  //           Our experienced staff is ready to meet all of your bay area
  //           corporate car service needs, including last-minute changes.
  //           <br />
  //           So give us a call whenever you need a bay area corporate limousine.{" "}
  //           <img
  //             alt=""
  //             className="aligncenter dont_scale wow animated fadeInDown"
  //             data-animation="fadeInDown"
  //             src="img/la-limo-rentals.png"
  //             style={{ visibility: "hidden", animationName: "none" }}
  //           />
  //           <div className="clearfix"></div>
  //         </div>
  //       </div>
  //     </div>
  //   </section>
  // </div>;