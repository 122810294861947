/** @format */

import React, { useRef } from "react";
import MainFooter from "../components/MainFooter";
import { MainHeader } from "../components/MainHeader";
import { NavLink } from "react-router-dom";

import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { TextArea } from "../blocks/Forms/TextArea";
import globalService from "../services/globalService";
import { ReactToast, ReactToastSuccess } from "../blocks/Modals/ReactToast";

export const ContactUs = () => {
  const formikRef = useRef();

  const LoginSchema = Yup.object().shape({
    fullname: Yup.string().required("Enter The Fullname"),
    email: Yup.string().email().required("Enter Your Email"),
    phone: Yup.string().required("Enter The phone"),
  });

  const values = {
    fullname:"",
    email: "",
    phone: "",
    comment:""
  };

  const handleSubmit = async(values) => {
    let res = await globalService.contactUs(values);
    if (res.status) {
      ReactToastSuccess(res.message)
    } else {
      ReactToast("opps something went wrong")
    }
   
  };
   
  return (
    <>
      <MainHeader />
      <>
        <div className="container mt-5">
          <div className="row">
            <div className="col-md-6 p-5">
              <h3>Contact Us</h3>
              <div className="container"></div>
              <div className="ors-col-max-width mt-4">
                <Formik
                  innerRef={formikRef}
                  enableReinitialize={true}
                  initialValues={values}
                  validationSchema={LoginSchema}
                  onSubmit={(values) => {
                    handleSubmit(values);
                  }}>
                  {({ errors, touched }) => (
                    <Form className="login_form position-relative">
                      <div className="form-group form-group-lg">
                        <label for="username-login" className="label-lg">
                          Fullname <font color="#FF0000">*</font>
                        </label>
                        <Field
                          type="text"
                          id="username-login"
                          name="fullname"
                          tabindex="4"
                          autofocus=""
                          className={`form-control mt-1 ${
                            touched.fullname && errors.fullname
                              ? "is-invalid"
                              : ""
                          }`}
                        />
                      </div>
                      <div className="form-group form-group-lg">
                        <label for="username-login" className="label-lg">
                          Email <font color="#FF0000">*</font>
                        </label>
                        <Field
                          type="text"
                          id="username-login"
                          name="email"
                          tabindex="4"
                          autofocus=""
                          autocomplete="email"
                          className={`form-control mt-1 ${
                            touched.email && errors.email ? "is-invalid" : ""
                          }`}
                        />
                      </div>
                      <div className="form-group form-group-lg">
                        <label for="username-login" className="label-lg">
                          Phone <font color="#FF0000">*</font>
                        </label>
                        <Field
                          type="text"
                          id="username-login"
                          name="phone"
                          tabindex="4"
                          autofocus=""
                          autocomplete="email"
                          className={`form-control mt-1 ${
                            touched.phone && errors.phone ? "is-invalid" : ""
                          }`}
                        />
                      </div>
                      <div className="form-group form-group-lg">
                        <label for="username-login" className="label-lg">
                          Comments
                        </label>
                        <Field
                          component={TextArea}
                          name="comment"
                          id="username-login"
                        />
                      </div>
                      <div className="form-group mt-4 text-center">
                        <button type="submit" className="contact-btn">
                          Submit
                        </button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
            <div class="col-lg-3 col-md-3 p-5 col-sm-6 mt-1">
              <h2
                class="wow animated fadeIn animated"
                data-animation="fadeIn"
                style={{ visibility: "visible", animationName: "fadeIn" }}>
                Contact Info
              </h2>
              <div class="clearfix clearfix-height10"></div>
              <div class="special-text marine-special-text" data-animation="">
                Address:
              </div>
              43B Glen Cove Rd Suite B115 Greenvale, <br/> NY 11548
              <div class="clearfix clearfix-height20"></div>
              <div class="special-text marine-special-text" data-animation="">
                Email:
              </div>
              info@continentalclubcar.com
            </div>
          </div>
        </div>
        <div className="row row-fluid padding-bottom20 mt-2">
          <h2 align="center" style={{ fontWeight: "700" }}>
            Our <img src="/images/five-stars.png" width="200" height="118" />{" "}
            Reviews &amp; Testimonials
          </h2>
        </div>
      </>
      <MainFooter />
    </>
  );
};
