/** @format */

import React from "react";
import { NavLink } from "react-router-dom";

const MainFooter = () => {
  return (
    <>
      <footer id="footer">
        <div className="smallest-padding" id="main-footer">
          <div className="container">
            <div className="row">
              <div className="col-lg-3 col-md-3 col-sm-3">
                <div className="widget widget_text" id="text-2">
                  <div align="center" className="textwidget">
                    <img src="/images/Continental-Club-Car-Full-Logo.png" className="foot-logo" />
                    <p>
                      We provide our clients with on time professional ground
                      transportation to the Tri State. In addition we offer the
                      right type of vehicle for special events, a night on the
                      town, door to door service as well as an hourly rate.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-3">
                <div
                  className="widget widget_latest_posts_entries"
                  id="latest-posts-3">
                  <h4>Services</h4>
                  <div className="blog-post">
                    <NavLink
                      className="post-title"
                      to="/services/nyc-li-car"
                      title="Single Blog Post"
                      onClick={() => {
                        window.scrollTo(0, 350);
                      }}>
                      New York Tri State Area Luxury Car
                    </NavLink>
                  </div>
                  <div className="blog-post">
                    <NavLink
                      className="post-title"
                      to="/services/nyc-nj-limo"
                      title="Single Blog Post"
                      onClick={() => {
                        window.scrollTo(0, 350);
                      }}>
                      New York Tri State Area Luxury Suv
                    </NavLink>
                  </div>
                  <div className="blog-post">
                    <NavLink
                      className="post-title"
                      to="/services/nyc-corporate-transportation"
                      title="Single Blog Post"
                      onClick={() => {
                        window.scrollTo(0, 350);
                      }}>
                      New York Tri State Area Luxury Sprinter Van
                    </NavLink>
                  </div>
                  {/* <div className="blog-post">
                    <NavLink
                      className="post-title"
                      to="bay-area-corporate-transportation"
                      title="Single Blog Post">
                      Bay Area Corporate Transportation
                    </NavLink>
                  </div> */}
                </div>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-3">
                <div
                  className="widget widget_latest_posts_entries"
                  id="latest-posts-3">
                  <h4>Airports</h4>
                  <div className="blog-post">
                    <NavLink
                      className="post-title"
                      to="/service/nyc-li-limo-car-transfer"
                      title="Single Blog Post"
                      onClick={() => {
                        window.scrollTo(0, 350);
                      }}>
                      New York Tri State Area (LGA)
                    </NavLink>
                  </div>
                  <div className="blog-post">
                    <NavLink
                      className="post-title"
                      to="/service/ct-limo-car-transfer-service"
                      title="Single Blog Post"
                      onClick={() => {
                        window.scrollTo(0, 350);
                      }}>
                      New York Tri State Area (JFK)
                    </NavLink>
                  </div>
                  <div className="blog-post">
                    <NavLink
                      className="post-title"
                      to="/service/nj-limo-car-transfer-service"
                      title="Single Blog Post"
                      onClick={() => {
                        window.scrollTo(0, 350);
                      }}>
                      New York Tri State Area (EWR)
                    </NavLink>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-3">
                <div className="widget widget_text" id="text-4">
                  <h4>Contact</h4>
                  <div className="textwidget">
                    <ul className="iconic-list">
                      <li>Continental Club Car</li>
                      <li>43B Glen Cove Rd Suite B115 Greenvale, NY 11548</li>
                      <li>info@continentalclubcar.com</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="lower-footer">
          <div className="container">
            <span className="copyright">
              © 2018 <span style={{ color: "#FFF" }}>Continental Club Car</span>{" "}
              . All Rights Reserved
            </span>{" "}
            |
            <span className="copyright">
              {" "}
              By:{" "}
              <NavLink to="https://www.semanticglobal.com">
                Semantic Global Services
              </NavLink>
            </span>
          </div>
        </div>
      </footer>
    </>
  );
};

export default MainFooter;
