/** @format */

import React from "react";
import { NavLink } from "react-router-dom";
import MainFooter from "../components/MainFooter";
import { MainHeader } from "../components/MainHeader";
import FindVehicle from "./Vehicle/BookVehicle";

const Home = () => {
  return (
    <>
      <div class="container">
        <div
          style={{
            textAlign: "center",
            marginTop: "20px",
            marginBottom: "20px",
          }}>
          <NavLink
            to="/reviews"
            class="home_stars"
            style={{
              marginLeft: "3%",
              display: "inlineBlock",
              textAlign: "center",
            }}>
            <img src="/images/stars.png" width="350" height="90" alt="stars" />
            <h3 style={{ margin: 0 }}>customer reviews</h3>
          </NavLink>
        </div>
        <div class="row our-fleet">
          {/* <div class="col-sm-6 fleet-image">
            <NavLink
              to="javascript:void(0)"
              onclick="if (!window.__cfRLUnblockHandlers) return false; showModal('/images/mercedesSclass-int.jpeg')">
              <img
                src="/images/mercedesSclass.jpeg"
                width="767"
                height="492"
                alt="mercedesSclass"
              />
            </NavLink>
          </div>
          <div class="col-sm-6 fleet-detail">
            <h2>First Class Executive Sedans</h2>
            <p>Mercedes-Benz or similer</p>
            <ul class="specs">
              <li>
                <i class="group"></i>
                <span>2</span>
              </li>
              <li>
                <i class="briefcase"></i>
                <span>2</span>
              </li>
            </ul>
            <div class="features">
              <div class="feature">
                <i class="clock"></i>
                <span>
                  Free 45 minutes wait time for airport pickups, 5 mins for all
                  others.
                </span>
              </div>
              <div class="feature">
                <i class="chauffeur"></i>
                <span>Fully certified professional chauffeurs</span>
              </div>
              <div class="feature">
                <i class="bottle"></i>
                <span>Complimentary bottled water</span>
              </div>
              <div class="feature">
                <i class="meetgreet"></i>
                <span>
                  Airport Meet &amp; Greet Service Available (only for domestic
                  flights)
                </span>
              </div>
            </div>
            <div class="fleet-btn">
              <NavLink to="/club-limousine-reservations" class="book_now">
                Book Now
              </NavLink>
            </div>
          </div> */}
        </div>
        <div class="row our-fleet">
          <div class="col-sm-6 fleet-image">
            <NavLink
              to="javascript:void(0)"
              onclick="if (!window.__cfRLUnblockHandlers) return false; showModal('/images/continentalL-int.jpeg')">
              <img
                src="/images/continentalL.jpeg"
                width="767"
                height="492"
                alt="continentalL"
              />
            </NavLink>
          </div>
          <div class="col-sm-6 fleet-detail mt-5">
            <h2>Luxury Sedans</h2>
            <p>Lincoln Continental or Similar</p>
            <ul class="specs">
              <li>
                <i class="group"></i>
                <span>2</span>
              </li>
              <li>
                <i class="briefcase"></i>
                <span>2</span>
              </li>
            </ul>
            <div class="features">
              <div class="feature">
                <i class="clock"></i>
                <span>
                  Free 45 minutes wait time for airport pickups, 5 mins for all
                  others.
                </span>
              </div>
              <div class="feature">
                <i class="chauffeur"></i>
                <span>Fully certified professional chauffeurs</span>
              </div>
            </div>
            <div class="fleet-btn">
              <NavLink to="/club-limousine-reservations" class="book_now">
                Book Now
              </NavLink>
            </div>
          </div>
        </div>
        <div class="row our-fleet mt-5">
          <div class="col-sm-6 fleet-image">
            <NavLink
              to="javascript:void(0)"
              onclick="if (!window.__cfRLUnblockHandlers) return false; showModal('/images/cadillacescalade-int.jpeg')">
              <img
                src="/images/transprant-car.png"
                width="767"
                height="492"
                alt="transprant-car"
              />
            </NavLink>
          </div>
          <div class="col-sm-6 fleet-detail">
            <h2>Executive SUVs</h2>
            <p>Cadillac Escalade ESV, Suburban, Navigator</p>
            <ul class="specs">
              <li>
                <i class="group"></i>
                <span>5</span>
              </li>
              <li>
                <i class="briefcase"></i>
                <span>5</span>
              </li>
            </ul>
            <div class="features">
              <div class="feature">
                <i class="clock"></i>
                <span>
                  Free 45 minutes wait time for airport pickups, 5 mins for all
                  others.
                </span>
              </div>
              <div class="feature">
                <i class="chauffeur"></i>
                <span>Fully certified professional chauffeurs</span>
              </div>
              <div class="feature">
                <i class="meetgreet"></i>
                <span>
                  Airport Meet &amp; Greet Service Available ( only for domestic
                  flights )
                </span>
              </div>
            </div>
            <div class="fleet-btn">
              <NavLink to="/club-limousine-reservations" class="book_now">
                Book Now
              </NavLink>
            </div>
          </div>
        </div>
        <div class="row our-fleet mt-5">
          <div class="col-sm-6 fleet-image">
            <NavLink
              to="javascript:void(0)"
              onclick="if (!window.__cfRLUnblockHandlers) return false; showModal('/images/sprinterG-int.jpeg')">
              <img
                src="/images/sprinterG.jpeg"
                width="767"
                height="492"
                alt="sprinterG"
              />
            </NavLink>
          </div>
          <div class="col-sm-6 fleet-detail">
            <h2>Executive Sprinter Van</h2>
            <p>Mercedes-Benz Sprinter With Air Suspension System</p>
            <ul class="specs">
              <li>
                <i class="group"></i>
                <span>6-14</span>
              </li>
              <li>
                <i class="briefcase"></i>
                <span>6-14</span>
              </li>
            </ul>
            <div class="features">
              <div class="feature">
                <i class="clock"></i>
                <span>
                  Free 45 minutes wait time for airport pickups,
                  <br />
                  Airport Meet &amp; Greet Service Available (only for domestic
                  flights)
                </span>
              </div>
              <div class="feature">
                <i class="chauffeur"></i>
                <span>Fully certified professional chauffeurs</span>
              </div>
              <div class="feature">
                <i class="bottle"></i>
                <span>Complimentary bottled water</span>
              </div>
              <div class="feature">
                <i class="wifi"></i>
                <span>
                  Free WiFi
                  <br />
                  Apple TV &amp; Table
                  <br />
                  110/USB w/Inverter (Every Row)
                </span>
              </div>
            </div>
            <div class="fleet-btn">
              <NavLink to="/club-limousine-reservations" class="book_now">
                Book Now
              </NavLink>
            </div>
          </div>
        </div>
        <div class="row row-fluid padding-top50">
          <h2
            style={{
              fontWeight: "800",
              textAlign: "center",
              fontSize: "35px",
            }}>
            <img src="/images/stars.png" width="350" alt="stars" height="90" />
            &nbsp;&nbsp;Our Reviews &amp; Testimonials
          </h2>
        </div>
        <script
          src="https://apps.elfsight.com/p/platform.js"
          defer=""
          type="text/javascript"></script>
      </div>
    </>
  );
};

export default Home;
