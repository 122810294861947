/** @format */

import React from "react";
import { NavLink } from "react-router-dom";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { LogoutUser } from "../../Auth/authSlice";

export const Header = (props) => {
  const [toogle, setToogle] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const logout = () => {
   dispatch(LogoutUser());
    navigate("/admin-login");
  };

  return (
    <>
      <header className="header">
        <nav className="navbar navbar-expand-lg navbar-light thenavbar">
          <Link
            className={
              props.sidebarFull ? "navbar-brand logo_brand" : "navbar-brand"
            }
            to="/">
            <img class="logo" src="/images/logo.png" alt="Logo" />
            <img
              class="logo_full mt-3"
              src="/images/logo_full.png"
              alt="Logo"
            />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <ul className="list-unstyled expander_icon d-lg-none d-block">
            <li>
              <a className="expander gray_blue" href="#" title="Expander">
                <span>
                  <img className="gray_blue" src="img/Expander.svg" alt="" />
                </span>
              </a>
            </li>
          </ul>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <div aria-label="breadcrumb" className="mr-auto">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <a href="#">
                    <img className="gray_blue" src="/images/home.svg" alt="" />
                  </a>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  ADMIN DASHBOARD
                </li>
              </ol>
            </div>
            <ul className="navbar-nav my-2 my-lg-0">
              <li className="nav-item dropdown user_dropdwn usr_Profile">
                <span
                  className="nav-link dropdown-toggle"
                  to="features"
                  id="navbarDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false">
                  <span className="profile_pic">
                    <img src="/images/user.png" alt />
                  </span>
                  <span className="text-secondary"> Admin </span>
                </span>
                <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                  <span className="dropdown-item" onClick={logout}>
                    Logout
                  </span>
                </div>
              </li>
            </ul>
          </div>
        </nav>
      </header>
    </>
  );
};
