/** @format */

import { GetApi, PostApi, UpdateApi } from "./index";

class AdminService {
  login({ email, password }) {
    return PostApi(`/admin/login`, { email, password });
  }

  carAdd(formData) {
    return PostApi(`/admin/car/add`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  carUpdate(formData) {
    return PostApi(`/admin/car/update`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  getCar() {
    return GetApi(`/admin/car/get`);
  }

  getBookings(page) {
    return GetApi(`/admin/getBookings?page=${page}`);
  }

  updateBookingStatus(status, id) {
    return UpdateApi(`/admin/updateBookingStatus`, { status, bookingId: id });
  }
}

const adminService = new AdminService();

export default adminService;
