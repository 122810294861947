/** @format */

import React, { useEffect, useRef } from "react";
import MainFooter from "../components/MainFooter";
import { MainHeader } from "../components/MainHeader";
import { NavLink, useNavigate } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { clearError, requestToRegister } from "./authSlice";
import { useDispatch, useSelector } from "react-redux";
import { ReactToast } from "../blocks/Modals/ReactToast";
import { BorderSpinner } from "../blocks/Loaders/BorderSpinner";
import authservice from "../services/authService";

const Register = () => {
  const { user, error, status } = useSelector((state) => state.auth);

  const formikRef = useRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const RegisterSchema = Yup.object().shape({
    fname: Yup.string().required("Enter Your First Name"),
    lname: Yup.string().required("Enter Your Last Name"),
    email: Yup.string().email().required("Enter Your Email"),
    phone: Yup.string().required("Enter Your Phone"),
    password: Yup.string().required("Enter The Password"),
  });

  const values = {
    fname: "",
    lname: "",
    email: "",
    phone: "",
    password: "",
    account_type:"0"
  };

  const handleSubmit = async (values) => {
    console.log(values,"values")
    const res = await authservice.register(values);
    if (res.status) {
      navigate(`/verifyMail?email=${values.email}`)
    }
    // dispatch(requestToRegister(values));
  };

  useEffect(() => {
    if (error) {
      ReactToast(error);
      dispatch(clearError());
    }
  }, [error]);

  useEffect(() => {
    if (user == null) {
      navigate("/register");
    } else {
      navigate("/dashboard");
    }
  }, [user]);

  return (
    <>
      <MainHeader />
      <>
        <div className="container mt-5 mb-5">
          <div className="page-heading style3 wrapper border-bottom ">
            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-6">
                <h1>Bay Area Limousine Reservations</h1>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-6">
                <p className="breadcrumbs">
                  <span typeof="v:Breadcrumb">
                    <NavLink to="/" property="v:title" rel="v:url">
                      Home
                    </NavLink>
                  </span>
                  <span className="delimiter">|</span>
                  <span className="current">Limo Quote</span>
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="container find-vehicle-container">
          <header id="top" className="ors-menu-top">
            <nav className="navbar">
              <div
                className="container-fluid mt-3"
                z
                id="accountInformationHeader">
                <ul className="nav navbar-nav pull-left">
                  <li>
                    <NavLink to="/club-limousine-reservations">New Reservation</NavLink>
                  </li>
                </ul>
              </div>
            </nav>
          </header>

          <div className="row">
            <div className="col-md-6 p-5">
              <div className="container">
                <div className="row ride-info">
                  <span className="panel-title ml-5">
                    <span>Create Account</span>
                  </span>
                </div>
              </div>
              <div className="ors-col-max-width mt-4">
                <Formik
                  innerRef={formikRef}
                  enableReinitialize={true}
                  initialValues={values}
                  validationSchema={RegisterSchema}
                  onSubmit={(values) => {
                    handleSubmit(values);
                  }}>
                  {({ errors, touched }) => (
                    <Form className="login_form position-relative">
                      <div className="form-group form-group-lg">
                        <label for="username-login" className="label-lg">
                          First Name
                        </label>
                        <Field
                          type="text"
                          name="fname"
                          placeholder="First Name"
                          id="username-login"
                          tabindex="4"
                          autofocus=""
                          className={`The-form-select  ${touched.fname && errors.fname
                              ? "is-invalid form-control"
                              : ""
                            }`}
                        />
                        {errors.fname && touched.fname ? (
                          <div className="d-block invalid-feedback ">
                            {errors.fname}
                          </div>
                        ) : null}
                      </div>

                      <div className="form-group form-group-lg mt-4">
                        <label for="username-login" className="label-lg">
                          Last Name
                        </label>
                        <Field
                          type="text"
                          name="lname"
                          placeholder="Last Name"
                          id="username-login"
                          tabindex="4"
                          autofocus=""
                          autocomplete="email"
                          className={`The-form-select  ${touched.lname && errors.lname
                              ? "is-invalid form-control"
                              : ""
                            }`}
                        />
                        {errors.lname && touched.lname ? (
                          <div className="d-block invalid-feedback ">
                            {errors.lname}
                          </div>
                        ) : null}
                      </div>
                      <div className="form-group form-group-lg mt-4">
                        <label for="username-login" className="label-lg">
                          Email Address
                        </label>
                        <Field
                          type="text"
                          name="email"
                          placeholder="Email Address"
                          id="username-login"
                          tabindex="4"
                          autofocus=""
                          autocomplete="email"
                          className={`The-form-select  ${touched.email && errors.email
                              ? "is-invalid form-control"
                              : ""
                            }`}
                        />
                        {errors.email && touched.email ? (
                          <div className="d-block invalid-feedback ">
                            {errors.email}
                          </div>
                        ) : null}
                      </div>
                      <div className="form-group form-group-lg mt-4">
                        <label for="username-login" className="label-lg">
                          Account Type
                        </label>
                        <Field
                          type="text"
                          as="select"
                          name="account_type"
                          className={`The-form-select`}
                        >
                          <option value="0" selected>personal</option>
                          <option value="1">corporate</option>
                        </Field>

                      </div>
                      <div className="form-group form-group-lg mt-4">
                        <label for="username-login" className="label-lg">
                          Mobile Phone
                        </label>
                        <Field
                          type="text"
                          name="phone"
                          placeholder="(555) 555-5555"
                          id="username-login"
                          tabindex="4"
                          autofocus=""
                          autocomplete="email"
                          className={`The-form-select  ${touched.phone && errors.phone
                              ? "is-invalid form-control"
                              : ""
                            }`}
                        />
                        {errors.phone && touched.phone ? (
                          <div className="d-block invalid-feedback ">
                            {errors.phone}
                          </div>
                        ) : null}
                      </div>

                      <div className="form-group form-group-lg mt-4">
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}>
                          <label for="password-login" className="label-lg">
                            Password
                          </label>
                          <div className="pull-right">
                            <NavLink
                              to="#"
                              id="forgotPassLink">
                              Forgot password?
                            </NavLink>
                          </div>
                        </div>
                        <Field
                          type="password"
                          name="password"
                          id="password-login"
                          tabindex="5"
                          autocomplete="current-password"
                          className={`The-form-select  ${touched.password && errors.password
                              ? "is-invalid form-control"
                              : ""
                            }`}
                        />
                        {errors.password && touched.password ? (
                          <div className="d-block invalid-feedback ">
                            {errors.password}
                          </div>
                        ) : null}
                        <div className="form-group mt-4">
                          <button type="submit" disabled={status == "loading" ? true : false} style={{ width: "100%" }}>
                            {status == "loading" ? <BorderSpinner /> : "Create Account"}
                          </button>
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
              <div className="text-center mt-3">
                I already have an account?
                <NavLink to="/login">Log in Now</NavLink>
              </div>
            </div>
            <div className="col-md-6 p-5 mb-4">
              <div className="container">
                <div className="row ride-info">
                  <span className="panel-title ml-5">
                    <span>Benefits of Creating an Account</span>
                  </span>
                </div>
              </div>
              <div className="ors-col-max-width mt-5">
                <ul className="ors-list-default">
                  <li>Book rides even faster using stored account details.</li>
                  <li>Modify trip details.</li>
                  <li>Access invoices and payment receipts.</li>
                  <li>Reporting tools.</li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="row row-fluid padding-bottom20 mt-2">
          <h2 align="center" style={{ fontWeight: "700" }}>
            Our <img src="/images/five-stars.png" width="200" height="118" />{" "}
            Reviews &amp; Testimonials
          </h2>
        </div>
      </>
      <MainFooter />
    </>
  );
};

export default Register;
