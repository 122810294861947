/** @format */

import React from "react";
import { useState, useEffect, useRef } from "react";
import { useFormikContext } from "formik";

const PickupAddress = ({ field, form, id, getPickupLatLng, ...props }) => {
  const [pickupAddress, setPickupAddress] = useState(field.value);
  const [pickupLat, setPickupAddressLat] = useState("");
  const [pickupLng, setPickupAddressLng] = useState("");
  const { setFieldValue, getFieldMeta } = useFormikContext();
  const { name, value } = field;

  const getPickupAddress = async () => {
    console.log("call clall")
    let autocomplete = new window.google.maps.places.Autocomplete(
      document.getElementById(id),
      {
        types: ["geocode"],
      }
    );
    window.google.maps.event.addListener(autocomplete, "place_changed", function () {
      var placeorg = autocomplete.getPlace();
      setPickupAddress(placeorg.formatted_address);
      setPickupAddressLat(placeorg.geometry.location.lat());
      setPickupAddressLng(placeorg.geometry.location.lng());
    });
  };

  useEffect(() => {
    getPickupAddress();
  }, [value]);

  useEffect(() => {
    getPickupLatLng(pickupLat, pickupLng);
    setFieldValue(name, pickupAddress);
  }, [pickupLat, pickupLng]);

  return (

    <input name={name} id={id} {...field} {...props} value={value} />
  )
};

export default PickupAddress;
