/** @format */

import { createSlice } from "@reduxjs/toolkit";
import { Navigate } from "react-router-dom";
import authservice from "../services/authService";

const STATUS = Object.freeze({
  IDLE: "idle",
  LOADING: "loading",
});

const initialState = {
  user: null,
  status: STATUS.IDLE,
  error: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    authRequest(state, action) {
      state.user = action.payload;
    },

    setStatus(state, action) {
      state.status = action.payload;
    },

    setError(state, action) {
      state.error = action.payload;
    },

    clearError(state, action) {
      state.error = null;
    },

    LogoutTheUser(state, action) {
      state.user = null;
    },
  },
});

export const { authRequest, setStatus, setError, clearError, LogoutTheUser } =
  authSlice.actions;

export function requestToRegister(values) {
  return async function requestToRegisterThunk(dispatch, getState) {
    dispatch(setStatus(STATUS.LOADING));
    try {
      const res = await authservice.register(values);
      if (res.status) {
        dispatch(authRequest(res));
        dispatch(setStatus(STATUS.IDLE));
      } else {
        dispatch(setError(res.message));
        dispatch(setStatus(STATUS.IDLE));
      }
    } catch (error) {
      console.log(error, "Register Reducer");
      dispatch(setError("opps something went wrong"));
    }
  };
}

export function requestToLogin(values,type) {
  return async function requestToLoginThunk(dispatch, getState) {
    dispatch(setStatus(STATUS.LOADING));
    try {
      const res = await authservice.login(values,type);
      if (res.status) {
        dispatch(authRequest(res));
        dispatch(setStatus(STATUS.IDLE));
      } else {
        dispatch(setError(res.message));
        dispatch(setStatus(STATUS.IDLE));
      }
    } catch (error) {
      console.log(error, "Login Reducer");
      dispatch(setError("opps something went wrong"));
    }
  };
}



export function LogoutUser() {
  return async function requestToLogoutThunk(dispatch, getState) {
    dispatch(LogoutTheUser());
    Navigate("/blog/login");
  };
}

export default authSlice;
