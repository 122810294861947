import React from 'react'
import MainFooter from '../components/MainFooter';
import { MainHeader } from '../components/MainHeader';

const Reviews = () => {
  return (
    <>
      <MainHeader />
      <div className='mb-5'>
        <h3 style={{ display: "flex", justifyContent: "center" }}>
          What our customers say
        </h3>
      </div>
      <MainFooter />
    </>
  );
}

export default Reviews