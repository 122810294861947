/** @format */

import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { FullLoader } from "../../blocks/Loaders/FullLoader";
import { ReactToast, ReactToastSuccess } from "../../blocks/Modals/ReactToast";
import bookingService from "../../services/BookingService";
import {
  removePassenger,
  removeSearch,
  removeSearchVehicle,
  removeSelect,
  removeVehicleCurruntSelected,
} from "./VehicleSlice";

const CreateBooking = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  let getQuery = (key) => {
    return new URL(window.location.href).searchParams.get(key);
  };

  const { curruntVehicle, searchVehicle, user, passengerData } = useSelector(
    (state) => {
      const curruntVehicle = state.vehicle.curruntVehicle;
      const searchVehicle = state.vehicle.searchVehicle;
      const user = state.auth.user;
      const passengerData = state.vehicle.passengersData;
      return {
        curruntVehicle,
        searchVehicle,
        user,
        passengerData,
      };
    }
  );
 

  const createBook = async () => {
    let req = {
      CarId:curruntVehicle.id,
      ServiceTypeId: searchVehicle.ServiceTypeId,
      pickup_date: searchVehicle.pickup_date,
      pickup_time: searchVehicle.pickup_time,
      Luggage: searchVehicle.Luggage,
      Passenger: searchVehicle.Passengers,
      stops: searchVehicle.stops,
      childSeat: searchVehicle.childSeat,
      dropoff_address: searchVehicle.dropoff_address,
      dropoff_lat: searchVehicle.dropoff_lat,
      dropoff_lng: searchVehicle.dropoff_lng,
      pickup_address: searchVehicle.pickup_address,
      pickup_lat: searchVehicle.pickup_lat,
      pickup_lng: searchVehicle.pickup_lng,
      fare: Math.round(curruntVehicle.fare).toString(),
      Passengers: passengerData?.Passenger,
      bookingId: getQuery("bookingId"),
    };

    const res = await bookingService.createBook(req);
    if (res.status) {
      dispatch(removeVehicleCurruntSelected());
      dispatch(removeSearchVehicle());
      dispatch(removeSearch());
      dispatch(removeSelect());
      ReactToastSuccess(res.message);
      navigate("/dashboard");
    }
  };

  useEffect(() => {
    setTimeout(() => {
       if (getQuery("status") == "true" && getQuery("bookingId")) {
         createBook();
       } else {
         ReactToast("Opps somethig went wrong");
       }
    },[500])
   
   
  }, []);

  return (
    <>
      <FullLoader />
    </>
  );
};

export default CreateBooking;
