/** @format */

import React from "react";
import { NavLink } from "react-router-dom";
import MainFooter from "../../components/MainFooter";
import { MainHeader } from "../../components/MainHeader";

const NycAndNorthJersey = () => {
  return (
    <>
      <MainHeader />
      <div id="marine-content-inner">
        <section id="main-content">
          <div className="container">
            <NavLink to="/reviews">
              <img
                className="rev_btn"
                src="/images/customer-reviews.png"
                width="100"
                height="39"
              />
            </NavLink>
            <div className="page-heading style3 wrapper border-bottom ">
              <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-6">
                  <h1>NYC and Northern New Jersey</h1>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6">
                  <p className="breadcrumbs">
                    <span typeof="v:Breadcrumb">
                      <NavLink to="/" property="v:title" rel="v:url">
                        Home
                      </NavLink>
                    </span>
                    <span className="delimiter">|</span>
                    <span className="current">Services</span>
                  </p>
                </div>
              </div>
            </div>
            <div className="row padding-top60">
              <section className="col-lg-12 col-md-12 col-sm-12">
                <div className="row">
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="flexslider flexslider-thumbnail-gallery ">
                      <ul className="slides">
                        <li
                          className="flex-active-slide"
                          style={{
                            width: "100%",
                            float: "left",
                            marginRight: "-100%",
                            position: "relative",
                            opacity: 1,
                            display: "block",
                            zIndex: "2",
                          }}>
                          <img
                            alt=""
                            src="/images/lincoln.jpeg"
                            draggable="false"
                          />
                        </li>
                      </ul>
                      <ol className="flex-control-nav flex-control-thumbs"></ol>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <h2
                      className="special-text wow animated fadeInDown services-special-h3 animated"
                      data-animation="fadeInDown"
                      style={{
                        visibility: "visible",
                        animationName: "fadeInDown",
                      }}>
                      NYC and Northern New Jersey Area Limo: Affordable Quality
                    </h2>
                    <p>
                      Call Continental Car Club for NYC and Northern New Jersey
                      limo deals without compromise. Enjoy the elegance of
                      Lincoln and Mercedes town cars and sedans, a luxury SUV,
                      or a stretch limousine equipped with all the amenities you
                      would expect while a safe and polite chauffeur takes care
                      of all the driving. Our affordable limo rental makes all
                      that possible, from economical party limos, prom and
                      wedding limousines to top-flight corporate limo service.
                      So call us for superb Northern New Jersey limousine
                      service at highly competitive rates.
                    </p>
                    <p>
                      A limo makes any event a memorable occasion. Be sure to
                      book well in advance for prom and wedding season to be
                      assured of getting your first choice in vehicles. But
                      don't forget Continental car club limousines when you're
                      planning other occasions. Customers also love our
                      night-time city tours, whether for bachelor and
                      bachelorette parties or just a night on the town among
                      friends with our limo deals.
                    </p>
                    <p>
                      Whether for personal or business travel, our San Francisco
                      East Bay limo services get you to and from JFK, LGA, and
                      EWR airports stress-free and on time. We'll drop you off
                      at the right terminal for departures and meet your
                      arrivals at the baggage claim.
                    </p>
                    <NavLink
                      className="button biggest"
                      to="/club-limousine-reservations"
                      target="_self">
                      BOOK NOW
                    </NavLink>
                  </div>
                </div>
                <div className="clearfix clearfix-height30"></div>
                <div className="row row-fluid padding-bottom20">
                  <h2 align="center" style={{ fontWeight: "700" }}>
                    Our{" "}
                    <img
                      src="/images/five-stars.png"
                      width="200"
                      height="118"
                    />{" "}
                    Reviews &amp; Testimonials
                  </h2>
                </div>
                <div
                  className="full_bg full-width-bg marine-header2-full_bg3"
                  data-animation="">
                  <h3
                    className="special-text wow animated fadeInDown marine-header2-h3"
                    data-animation="fadeInDown"
                    style={{ visibility: "visible", animationName: "none" }}>
                    NYC and Northern New Jersey Luxury Transportation
                  </h3>
                  <div
                    className="special-text wow animated fadeInLeft marine-header2-special-text1"
                    data-animation="fadeInLeft"
                    style={{ visibility: "visible", animationName: "none" }}>
                    Black Car's East Bay limo deals are perfect for corporate
                    travel around town, including dining, event, and
                    entertainment venues.
                    <br />
                    Call CONTINENTAL CLUB CAR Luxury Transportation for all Your
                    east Nyc and Northern New Jersey limo service needs.{" "}
                    <img
                      alt=""
                      className="aligncenter dont_scale wow animated fadeInDown"
                      data-animation="fadeInDown"
                      src="/images/la-limo-rentals.png"
                      style={{ visibility: "visible", animationName: "none" }}
                    />
                    <div className="clearfix"></div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </section>
      </div>
      <MainFooter />
    </>
  );
};

export default NycAndNorthJersey;
