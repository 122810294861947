import React from 'react'
import MainFooter from '../../../components/MainFooter';
import { MainHeader } from '../../../components/MainHeader';
import { NavLink } from "react-router-dom";

export const OakLimoCarTransferService = () => {
  return (
    <>
      <MainHeader />
      <div id="marine-content-inner">
        <section id="main-content">
          <div className="container">
            <NavLink to="https://www.continentalclubcar.com/reviews.html">
              <img
                className="rev_btn"
                src="/images/customer-reviews.png"
                width="100"
                height="39"
              />
            </NavLink>
            <div className="page-heading style3 wrapper border-bottom ">
              <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-6">
                  <h1>Connecticut Airport Transportation</h1>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6">
                  <p className="breadcrumbs">
                    <span typeof="v:Breadcrumb">
                      <NavLink to="/" property="v:title" rel="v:url">
                        Home
                      </NavLink>
                    </span>
                    <span className="delimiter">|</span>
                    <span className="current">Services</span>
                  </p>
                </div>
              </div>
            </div>
            <div className="row padding-top60">
              <section className="col-lg-12 col-md-12 col-sm-12">
                <div className="row">
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="flexslider flexslider-thumbnail-gallery ">
                      <ul className="slides">
                        <li
                          className="flex-active-slide"
                          style={{
                            width: "100%",
                            float: "left",
                            marginRight: "-100%",
                            position: "relative",
                            opacity: 1,
                            display: "block",
                            zIndex: "2",
                          }}>
                          <img
                            alt=""
                            src="/images/oak-transportation.jpeg"
                            draggable="false"
                          />
                        </li>
                      </ul>
                      <ol className="flex-control-nav flex-control-thumbs"></ol>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <h2
                      className="special-text wow animated fadeInDown services-special-h3 animated"
                      data-animation="fadeInDown"
                      style={{
                        visibility: "visible",
                        animationName: "fadeInDown",
                      }}>
                      Dependable CT Airport Limousine Service
                    </h2>
                    <p>
                      Our company provides top-quality and affordable CT car
                      service. CONTINENTAL CLUB CAR's Connecticut Airport ground
                      transportation covers the East Bay and the entire San
                      Francisco Connecticut. Our CT airport transfer service also
                      includes SJC (San Jose) and SFO (San Francisco) as well as
                      the many municipal and private airports if your itinerary
                      forces you to transfer between different local airports.
                    </p>
                    <h2
                      className="special-text wow animated fadeInDown services-special-h3 animated"
                      data-animation="fadeInDown"
                      style={{
                        visibility: "visible",
                        animationName: "fadeInDown",
                      }}>
                      Connecticut Airport Car Service
                    </h2>

                    <p>
                      Take a few steps above the usual shuttle services and
                      taxis and treat yourself to an CT airport limo. With us
                      it's surprisingly affordable. Yet our CT car service
                      never compromises on quality, comfort, or safety. That
                      makes us a natural choice for CT airport transfer
                      services. We make sure that you'll arrive with adequate
                      time for baggage check in and clearing security.
                    </p>
                    <p>
                      For arrivals, you or your guests will be met by a
                      uniformed chauffeur and will bypass standard Connecticut
                      Airport ground transportation complications. And a luxury
                      sedan or limousine CT airport transfer service is
                      critical for making the right impression on important
                      business associates. Every vehicle in our Connecticut Airport
                      ground transportation fleet is impeccably maintained and
                      our chauffeurs are all experienced, well-trained, and
                      highly professional. Your CT car service choices include
                      town cars, sedans, and SUVs from BMW, Chevrolet, Lincoln,
                      and Mercedes-Benz.
                    </p>
                    <NavLink
                      className="button biggest"
                      to="/club-limousine-reservations"
                      target="_self">
                      BOOK NOW
                    </NavLink>
                  </div>
                </div>
                <div className="clearfix clearfix-height30"></div>
                <div className="row row-fluid padding-bottom20">
                  <h2 align="center" style={{ fontWeight: "700" }}>
                    Our{" "}
                    <img
                      src="/images/five-stars.png"
                      width="200"
                      height="118"
                    />{" "}
                    Reviews &amp; Testimonials
                  </h2>
                </div>
                <div
                  className="full_bg full-width-bg marine-header2-full_bg3"
                  data-animation="">
                  <h3
                    className="special-text wow animated fadeInDown marine-header2-h3"
                    data-animation="fadeInDown"
                    style={{
                      visibility: "visible",
                      animationName: "fadeInLeft",
                    }}>
                    Connecticut Luxury Transportation
                  </h3>
                  <div
                    className="special-text wow animated fadeInLeft marine-header2-special-text1"
                    data-animation="fadeInLeft"
                    style={{
                      visibility: "visible",
                      animationName: "fadeInLeft",
                    }}>
                    Black Car's East Bay limo deals are perfect for corporate
                    travel around town, including dining, event, and
                    entertainment venues.
                    <br />
                    Call CONTINENTAL CLUB CAR Luxury Transportation for all Your east
                    connecticut limo service needs.{" "}
                    <img
                      alt=""
                      className="aligncenter dont_scale wow animated fadeInDown"
                      data-animation="fadeInDown"
                      src="/images/la-limo-rentals.png"
                      style={{
                        visibility: "visible",
                        animationName: "fadeInLeft",
                      }}
                    />
                    <div className="clearfix"></div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </section>
      </div>
      <MainFooter />
    </>
  );
}
