/** @format */

import React, { useEffect, useState } from "react";
import { TbCarOff } from "react-icons/tb";
import { useDispatch } from "react-redux";
import BookingDialog from "../../blocks/Dialogs/BookingDialog";
import { openBookingDialog } from "../../pages/Vehicle/VehicleSlice";
import adminService from "../../services/adminService";
import { ReactToast, ReactToastSuccess } from "../../blocks/Modals/ReactToast";
import Pagination from "@mui/material/Pagination";



export const Bookings = () => {

  const [booking, setBooking] = useState([]);
  const [bookingDialogData, setBookingDialogData] = useState([]);
  const [pageApi, setPageApi] = useState(1);
  const dispatch = useDispatch()

  let getBookings = async () => {
    let res = await adminService.getBookings(pageApi);
    if (res.status) {
      setBooking(res);
    }
  };

  console.log(pageApi, "setPageApi");

  let updateStatus = async(status,id) => {
    let res = await adminService.updateBookingStatus(status, id);
    if (res.status) {
      ReactToastSuccess(res.message);
    } else {
      ReactToast("opps somethig went wrong")
    }
  }

    useEffect(() => {
    getBookings();
    }, [pageApi]);
    

  return (
    <>
      <BookingDialog booking={bookingDialogData} />
      <div class="container-fluid mt-2">
        <div className="row">
          <div className="col-12 mt-3">
            <table className="table ac-data-table ac-table-collapse ac-table-primary ac-table-responsive">
              <thead className="active">
                <tr id="ridesTableHead">
                  <th className="sortable number text-center">
                    <input
                      type="checkbox"
                      name="select-all-rides"
                      onclick="Ores4Rides.SelectAllRides(this);"
                    />
                    <span
                      title="Sort By Confirmation Number"
                      onclick="Ores4Rides.OnTableHeadClick($(this).parent(), 'ConfNumber');">
                      Conf. #
                    </span>
                  </th>
                  <th
                    className="sortable active descending text-center"
                    onclick="Ores4Rides.OnTableHeadClick(this, 'PickupDate');">
                    <span title="Sort By Date">Date</span>
                  </th>
                  <th
                    className="sortable text-center"
                    onclick="Ores4Rides.OnTableHeadClick(this, 'Passenger');">
                    <span title="Sort By Passenger">Passenger</span>
                  </th>
                  <th className="text-center">
                    <span>Routing Information</span>
                  </th>
                  <th
                    className="sortable text-center"
                    onclick="Ores4Rides.OnTableHeadClick(this, 'Status');">
                    <span title="Sort By Status">Status</span>
                  </th>
                  <th
                    className="sortable total text-center"
                    onclick="Ores4Rides.OnTableHeadClick(this, 'TotalAmount');">
                    <span title="Sort By Total">Total</span>
                  </th>
                  <th className="actions text-center">
                    <span>Actions</span>
                  </th>
                </tr>
              </thead>
              <tbody id="tableRides">
                {booking.rides && booking.rides.length > 0 ? (
                  booking.rides.map((el, i) => (
                    <>
                      <tr id="ridesTableHead">
                        <td className="sortable number text-center">
                          <span title="Sort By Confirmation Number">
                            {i + 1}
                          </span>
                        </td>
                        <td className="sortable active descending text-center">
                          <span title="Sort By Date">
                            {el.pickup_date}, {el.pickup_time}
                          </span>
                        </td>
                        <td className="sortable text-center">
                          <span title="Sort By Passenger">{el.Passenger}</span>
                        </td>
                        <td className="text-center">
                          <span>{el.pickup_address}</span> <br />
                          <span className="text-center">To</span> <br />
                          <span>{el.dropoff_address}</span>
                        </td>
                        <td className="sortable text-center">
                          <select
                            className="admin-status-select"
                            onChange={(e) =>
                              updateStatus(e.target.value, el.id)
                            }>
                            <option value={el.status}>{el.status}</option>
                            <option
                              value={el.status == "new" ? "Confirm" : "new"}>
                              {el.status == "new" ? "Confirm" : "new"}
                            </option>
                          </select>
                        </td>
                        <td>
                          <span title="Sort By Total">${el.fare}</span>
                        </td>
                        <td className="actions text-center">
                          <span>
                            <button
                              onClick={() => {
                                setBookingDialogData(el);
                                dispatch(openBookingDialog());
                              }}>
                              <TbCarOff /> Details
                            </button>
                          </span>
                        </td>
                      </tr>
                    </>
                  ))
                ) : (
                  <tr>
                    <td colspan="8">
                      <div className="content-empty">
                        <span className="text-muted">No Booking available</span>
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="mt-2 mb-4">
        <Pagination
          count={booking.pages}
          onChange={(e, value) => setPageApi(value)}
          color="primary"
        />
      </div>
    </>
  );
};
