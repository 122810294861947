/** @format */

import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useDispatch, useSelector } from "react-redux";
import {
  closeBookingDialog,
  openBookingDialog,
} from "../../pages/Vehicle/VehicleSlice";
import { TbCarOff } from "react-icons/tb";

export default function BookingDialog(props) {
  const { bookingDialog } = useSelector((state) => state.vehicle);
  const dispatch = useDispatch();
  const handleClickOpen = () => {
    dispatch(openBookingDialog());
  };

  const handleClose = () => {
    dispatch(closeBookingDialog());
  };

console.log(props,"BOOKING DIALOG")

  return (
    <React.Fragment>
      <Dialog
        fullWidth="lg"
        maxWidth="lg"
        open={bookingDialog}
        onClose={handleClose}>
        <DialogTitle>Booking Details</DialogTitle>
        <DialogContent>
          <div class="container-fluid mt-2">
            <div className="row">
              <div className="col-12 mt-3">
                <table className="table ac-data-table ac-table-collapse ac-table-primary ac-table-responsive">
                  <thead className="active">
                    <tr id="ridesTableHead" className="popup-heading">
                      <th className="sortable active descending text-center ">
                        <span>Date</span>
                      </th>
                      <th className="sortable active descending text-center">
                        <span>Time</span>
                      </th>
                      <th className="sortable text-center">
                        <span>Pickup Address</span>
                      </th>
                      <th className="sortable text-center">
                        <span>Dropoff Address</span>
                      </th>
                      <th className="sortable text-center">
                        <span>Stops</span>
                      </th>
                      <th className="sortable text-center">
                        <span>Passenger</span>
                      </th>
                      <th className="text-center">
                        <span>Luggage</span>
                      </th>
                      <th
                        className="sortable text-center"
                        onclick="Ores4Rides.OnTableHeadClick(this, 'Status');">
                        <span title="Sort By Status">Status</span>
                      </th>
                      <th
                        className="sortable total text-center"
                        onclick="Ores4Rides.OnTableHeadClick(this, 'TotalAmount');">
                        <span title="Sort By Total">Fare</span>
                      </th>
                    </tr>
                  </thead>
                  {props.booking && (
                    <tbody id="tableRides">
                      <tr id="ridesTableHead">
                        <td className="sortable active descending text-center">
                          <span title="Sort By Date">
                            {props.booking.pickup_date}
                          </span>
                        </td>
                        <td className="sortable active descending text-center">
                          <span title="Sort By Date">
                            {props.booking.pickup_time}
                          </span>
                        </td>
                        <td className="sortable text-center">
                          <span title="Sort By Passenger">
                            {props.booking.pickup_address}
                          </span>
                        </td>
                        <td className="sortable text-center">
                          <span title="Sort By Passenger">
                            {props.booking.dropoff_address}
                          </span>
                        </td>
                        <td className="sortable text-center">
                          <span title="Sort By Passenger">
                            {props.booking.dropoff_address}
                          </span>
                        </td>
                        <td className="sortable text-center">
                          <span title="Sort By Passenger">
                            {props.booking.Passenger}
                          </span>
                        </td>
                        <td className="text-center">
                          <span>{props.booking.Luggage}</span> <br />
                        </td>
                        <td className="sortable text-center">
                          <span>{props.booking.status}</span>
                        </td>
                        <td>
                          <span title="Sort By Total">
                            ${props.booking.fare}
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  )}
                </table>

                <div className="row">
                  <div className="col-6">
                    <h5> Passengers</h5>{" "}
                    <table className="table ac-data-table ac-table-collapse ac-table-primary ac-table-responsive">
                      <thead className="active">
                        <tr id="ridesTableHead" className="popup-heading">
                          <th className="sortable active descending text-center ">
                            <span>Name</span>
                          </th>
                          <th className="sortable active descending text-center">
                            <span>Email</span>
                          </th>
                          <th className="sortable text-center">
                            <span>Phone</span>
                          </th>
                        </tr>
                      </thead>
                      {props.booking.Passengers &&
                        props?.booking.Passengers.map((e, i) => (
                          <>
                            <tbody id="tableRides">
                              <tr id="ridesTableHead">
                                <td className="sortable active descending text-center">
                                  <span title="Sort By Date">
                                    {e.fname}
                                    {e.lane}
                                  </span>
                                </td>
                                <td className="sortable active descending text-center">
                                  <span title="Sort By Date">{e.email}</span>
                                </td>
                                <td className="sortable text-center">
                                  <span title="Sort By Passenger">
                                    {e.phone}
                                  </span>
                                </td>
                              </tr>
                            </tbody>
                          </>
                        ))}
                    </table>
                  </div>

                  <div className="col-4">
                    <h5> Child Seats</h5>{" "}
                    <table className="table ac-data-table ac-table-collapse ac-table-primary ac-table-responsive">
                      <thead className="active">
                        <tr id="ridesTableHead" className="popup-heading">
                          <th className="sortable active descending text-center ">
                            <span>Type</span>
                          </th>
                          <th className="sortable active descending text-center">
                            <span>seat</span>
                          </th>
                        </tr>
                      </thead>
                      {props.booking.childSeat &&
                        props?.booking.childSeat.map((e, i) => (
                          <>
                            <tbody id="tableRides">
                              <tr id="ridesTableHead">
                                <td className="sortable active descending text-center">
                                  <span title="Sort By Date">{e.type}</span>
                                </td>
                                <td className="sortable active descending text-center">
                                  <span title="Sort By Date">{e.seat}</span>
                                </td>
                              </tr>
                            </tbody>
                          </>
                        ))}
                    </table>
                  </div>
                  <div className="col-2"></div>
                </div>
              </div>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
