/** @format */

import { createSlice } from "@reduxjs/toolkit";

const STATUS = Object.freeze({
  IDLE: "idle",
  LOADING: "loading",
});

const initialState = {
  curruntVehicle: null,
  searchVehicle: null,
  passengersData: null,
  isSearch: false,
  isSelect: false,
  bookingDialog:false,
  status: STATUS.IDLE,
  error: null,
};

const vehicleSlice = createSlice({
  name: "vehicle",
  initialState,
  reducers: {
    addVehicleCurruntSelected(state, action) {
      state.curruntVehicle = action.payload;
    },
    removeVehicleCurruntSelected(state, action) {
      state.curruntVehicle = null;
    },
    searchNewVehicle(state, action) {
      state.searchVehicle = action.payload;
    },
    removeSearchVehicle(state, action) {
      state.searchVehicle = null;
    },
    setPassenger(state, action) {
      state.passengersData = action.payload;
    },
    removePassenger(state, action) {
      state.passengersData = null;
    },
    isSearch(state, action) {
      state.isSearch = true;
    },
    isSelect(state, action) {
      state.isSelect = true;
    },
    removeSearch(state, action) {
      state.isSearch = false;
    },
    removeSelect(state, action) {
      state.isSelect = false;
    },
    openBookingDialog(state, action) {
       state.bookingDialog = true;
    },
    closeBookingDialog(state,action) {
       state.bookingDialog = false;
    },
  },
});

export const {
  addVehicleCurruntSelected,
  searchNewVehicle,
  removeSearchVehicle,
  removeVehicleCurruntSelected,
  setPassenger,
  removePassenger,
  isSearch,
  isSelect,
  removeSearch,
  removeSelect,
  openBookingDialog,
  closeBookingDialog
} = vehicleSlice.actions;

export default vehicleSlice;
