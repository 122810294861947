/** @format */

import React, { useEffect, useRef, useState } from "react";
import {
  PaymentElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { BorderSpinner } from "../blocks/Loaders/BorderSpinner";
import { useDispatch, useSelector } from "react-redux";
import {ReactToastSuccess} from '../blocks/Modals/ReactToast'

export default function CheckoutForm() {

    const stripe = useStripe();
    const dispatch = useDispatch()
    const elements = useElements();
    const [message, setMessage] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
  
    const { passengerData,user } = useSelector((state) => {
    const passengerData = state.vehicle.passengersData;
    const user = state?.auth?.user.user;
      return { passengerData, user };
    }
    );
  
 

  useEffect(() => {
    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get("payment_intent_client_secret");

    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent.status) {
        case "succeeded":
          setMessage("Payment succeeded!");
          break;
        case "processing":
          setMessage("Your payment is processing.");
          break;
        case "requires_payment_method":
          setMessage("Your payment was not successful, please try again.");
          break;
        default:
          setMessage("Something went wrong.");
          break;
      }
    });
  }, [stripe]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);
    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: `${process.env.REACT_APP_BASE_URL}/v1/payment_redirect?id=${user._id}`,
      },
    });
    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error.type === "card_error" || error.type === "validation_error") {
      setMessage(error.message);
    } else {
      setMessage("An unexpected error occurred.");
    }
    setIsLoading(false);
  };

  const paymentElementOptions = {
    layout: "tabs",
    // fields: { billingDetails: { name: 'auto', address: { postalCode :'auto'} } },
  };

  return (
   

    <form id="payment-form" onSubmit={handleSubmit}>
      <PaymentElement id="payment-element" options={paymentElementOptions} />
      <button style={{backgroundColor:passengerData ? "#002250" : "#0022508f"}} disabled={passengerData && passengerData?.Passenger.length > 0 ? false : true || isLoading || !stripe || !elements} id="submit" className="w-100 mt-3 mb-5">
        <span id="button-text">
          {isLoading ? <BorderSpinner/> : "Pay now"}
        </span>
      </button>
      {/* Show any error or success messages */}
      {message && <div id="payment-message">{message}</div>}
    </form>

  );
}
