/** @format */

import React from "react";
import { useState, useEffect } from "react";
import { useFormikContext } from "formik";

const DropoffAddress = ({ field, form, id, getDropoffLatLng, ...props }) => {
  const [dropofAddress, setDropofAddress] = useState(field.value);
  const [dropoffLat, setDropofAddressLat] = useState("");
  const [dropoffLng, setDropofAddressLng] = useState("");

  const { setFieldValue } = useFormikContext();
  const { name, value } = field;

  const getDropOffAddress = async () => {
    let autocomplete = new window.google.maps.places.Autocomplete(
      document.getElementById(id),
      {
        types: ["geocode"],
      }
    );
    window.google.maps.event.addListener(autocomplete, "place_changed", function () {
      var placeorg = autocomplete.getPlace();
      setDropofAddressLat(placeorg.geometry.location.lat());
      setDropofAddress(placeorg.formatted_address);
      setDropofAddressLng(placeorg.geometry.location.lng());
    });
  };
  useEffect(() => {
    getDropOffAddress();
  }, []);

  useEffect(() => {
    getDropoffLatLng(dropoffLat, dropoffLng);
    setFieldValue(name, dropofAddress);
  }, [dropofAddress, dropoffLat, dropoffLng]);

  return <input name={name} id={id} {...field} {...props} value={value} />;
};

export default DropoffAddress;
