/** @format */

import { GetApi, PostApi, UpdateApi } from "./index";

class GlobalService {
  contactUs(data) {
    return PostApi(`/query/contactUs`, data);
  }
}

const globalService = new GlobalService();

export default globalService;
