/** @format */

import React, { useEffect, useState } from "react";
import MainFooter from "../components/MainFooter";
import { MainHeader } from "../components/MainHeader";
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { BasicDropdown } from "../blocks/Modals/BasicDropdown";
import { AiFillCar } from "react-icons/ai";
import { TbCarOff } from 'react-icons/tb'
import {BsFillEyeFill} from 'react-icons/bs'
import { RiEditBoxLine } from "react-icons/ri";
import bookingService from "../services/BookingService";
import BookingDialog from "../blocks/Dialogs/BookingDialog";
import { openBookingDialog } from "../pages/Vehicle/VehicleSlice";
import { ReactToast, ReactToastSuccess } from "../blocks/Modals/ReactToast";

export const Dashboard = () => {
  
  const [rides,setRides] = useState([])
  const { user } = useSelector((state) => state.auth);
  const [bookingDialogData,setBookingDialogData] = useState(null)
  const dispatch = useDispatch()

  let getRides = async() =>{
    let res = await bookingService.getRides();
    if (res.status) {
      setRides(res.result);
    }
  }

  useEffect(() => {
    getRides()
  }, [])


  let cancelBooking = async(id) => {
    let confirm = window.confirm("Are you sure, you want to cancel this booking?");
    if (confirm) {
      let res = await bookingService.cancelBooking(id,"cancel");
      if (res.status) {
        ReactToastSuccess(res.message)
      } else {
        ReactToast("opps something went wrong");
      }
    } else {
      return
    }
  }
  
  return (
    <>
      <>
        <MainHeader />
        <>
          <div className="container mt-5 mb-5">
            <div className="page-heading style3 wrapper border-bottom ">
              <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-6">
                  <h1>Reservations</h1>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6">
                  <p className="breadcrumbs">
                    <span typeof="v:Breadcrumb">
                      <NavLink to="/">Home</NavLink>
                    </span>
                    <span className="delimiter">|</span>
                    <span className="current">Limo Quote</span>
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="container find-vehicle-container">
            <header id="top" className="ors-menu-top">
              <nav className="navbar">
                <div className="container-fluid" id="accountInformationHeader">
                  <div
                    style={{
                      color: "rgb(15 101 228 / 87%)",
                      cursor: "pointer",
                      fontSize: "15px",
                      marginLeft: "5px",
                    }}
                    className="ml-0">
                    <RiEditBoxLine color="" size={18} />
                    <NavLink
                      to="/club-limousine-reservations"
                      className="ml-1">
                      New Reservation
                    </NavLink>
                  </div>
                  <div>
                    <BasicDropdown name={user?.user.fname} />
                  </div>
                </div>
              </nav>
            </header>

            <div className="row">
              <div className="col-md-12  p-3">
                <div className="container">
                  <div className="row ride-info">
                    <ul
                      className="ac-nav hidden-xs"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginTop: "10px",
                        listStyle: "none",
                      }}>
                      <li className="active">
                        <a href="#" title="Rides">
                          <span className="dashboard-btn active">
                            <AiFillCar margin={0} /> Rides
                          </span>
                        </a>
                      </li>
                    </ul>
                  </div>

                  <div className="col-12 mt-3">
                    <table className="table ac-data-table ac-table-collapse ac-table-primary ac-table-responsive">
                      <thead className="active">
                        <tr id="ridesTableHead">
                          <th className="sortable number text-center">
                            <input
                              type="checkbox"
                              name="select-all-rides"
                              onclick="Ores4Rides.SelectAllRides(this);"
                            />
                            <span
                              title="Sort By Confirmation Number"
                              onclick="Ores4Rides.OnTableHeadClick($(this).parent(), 'ConfNumber');">
                              Conf. #
                            </span>
                          </th>
                          <th
                            className="sortable active descending text-center"
                            onclick="Ores4Rides.OnTableHeadClick(this, 'PickupDate');">
                            <span title="Sort By Date">Date</span>
                          </th>
                          <th
                            className="sortable text-center"
                            onclick="Ores4Rides.OnTableHeadClick(this, 'Passenger');">
                            <span title="Sort By Passenger">Passenger</span>
                          </th>
                          <th className="text-center">
                            <span>Routing Information</span>
                          </th>
                          <th
                            className="sortable text-center"
                            onclick="Ores4Rides.OnTableHeadClick(this, 'Status');">
                            <span title="Sort By Status">Status</span>
                          </th>
                          <th
                            className="sortable total text-center"
                            onclick="Ores4Rides.OnTableHeadClick(this, 'TotalAmount');">
                            <span title="Sort By Total">Total</span>
                          </th>
                          <th className="actions text-center">
                            <span>Actions</span>
                          </th>
                        </tr>
                      </thead>
                      <tbody id="tableRides">
                        {rides && rides.length > 0 ? (
                          rides.map((el, i) => (
                            <>
                              <tr id="ridesTableHead">
                                <td className="sortable number text-center">
                                  <span title="Sort By Confirmation Number">
                                    {i + 1}
                                  </span>
                                </td>
                                <td className="sortable active descending text-center">
                                  <span title="Sort By Date">
                                    {el.pickup_date}, {el.pickup_time}
                                  </span>
                                </td>
                                <td className="sortable text-center">
                                  <span title="Sort By Passenger">
                                    {el.Passenger}
                                  </span>
                                </td>
                                <td className="text-center">
                                  <span>{el.pickup_address}</span> <br />
                                  <span className="text-center">To</span> <br />
                                  <span>{el.dropoff_address}</span>
                                </td>
                                <td className="sortable text-center">
                                  <span>{el.status}</span>
                                </td>
                                <td>
                                  <span title="Sort By Total">${el.fare}</span>
                                </td>
                                <td className="actions text-center ">
                                  <span>
                                    <button
                                      disabled={
                                        el.status == "Confirm" ||
                                        el.status == "cancel"
                                          ? true
                                          : false
                                      }
                                      style={{
                                        border: "none",
                                        backgroundColor: "transparent",
                                      }}
                                      onClick={() => cancelBooking(el.id)}>
                                      <TbCarOff size={22} color={"#002250"} />
                                    </button>
                                    <button
                                      style={{
                                        border: "none",
                                        backgroundColor: "transparent",
                                        cursor: "pointer",
                                      }}
                                      onClick={() => {
                                        setBookingDialogData(el);
                                        dispatch(openBookingDialog());
                                      }}>
                                      <BsFillEyeFill
                                        size={22}
                                        color={"#002250"}
                                      />
                                    </button>
                                  </span>
                                </td>
                              </tr>
                            </>
                          ))
                        ) : (
                          <tr>
                            <td colspan="8">
                              <div className="content-empty">
                                <span className="text-muted">
                                  No Rides available
                                </span>
                              </div>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div className="text-center pb-5">
              <NavLink to="#"> Privacy Policy </NavLink>{" "}
            </div>
          </div>

          <div className="row row-fluid padding-bottom20 mt-2">
            <h2 align="center" style={{ fontWeight: "700" }}>
              Our <img src="/images/five-stars.png" width="200" height="118" />{" "}
              Reviews &amp; Testimonials
            </h2>
          </div>
        </>
        <MainFooter />
        {bookingDialogData && <BookingDialog booking={bookingDialogData} />}
      </>
    </>
  );
};
