/** @format */

import React, { useEffect, useState } from "react";

export const AdminDashboard = () => {
  const [userNum, setUserNum] = useState(null);

  return (
    <>
      <div className="right_cntnt mt-4">
        <div className="row row_gap48">
          <div className="col-md-3 col-6">
            <div className="smsent_box">
              <h4>50</h4>
              <p>Total Number of Users</p>
            </div>
          </div>
          <div className="col-md-3 col-6">
            <div className="smsent_box">
              <h4> $ 0.00</h4>
              <p>Total Savings</p>
            </div>
          </div>
          <div className="col-md-3 col-6">
            <div className="smsent_box">
              <h4>0.00 </h4>
              <p>
                <span className="online_cricle"></span>Logged in users
              </p>
            </div>
          </div>
          <div className="col-md-3 col-6">
            <div className="smsent_box">
              <h4>0.00</h4>
              <p>Skip Traced</p>
            </div>
          </div>
        </div>

        {/* <div className="row row_gap48 justify-content-between">
          <div className="col-md-6 col-lg-6">
            <div className="chart_box hor_bar">
              <div className="dash_month d-flex justify-content-between">
                <h5>Account Overview</h5>
              </div>
              <div className="loc_bar">
                <div className="progrList">
                  <span>0.00</span>
                  <div className="d-flex">
                    <div className="progress prog_ress">
                      <div
                        className="progress-bar"
                        role="progressbar"
                        style={{ width: "4%" }}
                        aria-valuenow="90"
                        aria-valuemin="0"
                        aria-valuemax="100"></div>
                    </div>
                    <label>Total User</label>
                  </div>
                </div>
                <div className="progrList">
                  <span>0.00</span>
                  <div className="d-flex">
                    <div className="progress prog_ress">
                      <div
                        className="progress-bar"
                        role="progressbar"
                        style={{ width: "4%" }}
                        aria-valuenow="40"
                        aria-valuemin="0"
                        aria-valuemax="100"></div>
                    </div>
                    <label>Uniq click</label>
                  </div>
                </div>
                <div className="progrList">
                  <span>0.00</span>
                  <div className="d-flex">
                    <div className="progress prog_ress">
                      <div
                        className="progress-bar"
                        role="progressbar"
                        style={{ width: "4%" }}
                        aria-valuenow="7"
                        aria-valuemin="0"
                        aria-valuemax="100"></div>
                    </div>
                    <label>Saved</label>
                  </div>
                </div>
                <div className="progrList">
                  <span>0.00</span>
                  <div className="d-flex">
                    <div className="progress prog_ress">
                      <div
                        className="progress-bar"
                        role="progressbar"
                        style={{ width: "4%" }}
                        aria-valuenow="4"
                        aria-valuemin="0"
                        aria-valuemax="100"></div>
                    </div>
                    <label>Skip</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-5">
            <div className="chart_box">
              <div className="dash_month team_activti d-flex justify-content-between">
                <h5>Performance</h5>
              </div>
              <div className="d-flex justify-content-between perfom_btn">
                <button type="button" className="btn">
                  Past 24h
                </button>
                <button type="button" className="btn white_btn">
                  Past 7 days
                </button>
                <button type="button" className="btn white_btn">
                  Past 30 days
                </button>
              </div>
              <div className="loc_bar team_bar">
                <div className="progrList">
                  <h4 className="priceName d-flex justify-content-between">
                    <label>New Users</label>
                    <span>0.00</span>
                  </h4>
                  <div className="d-flex">
                    <div className="progress prog_ress">
                      <div
                        className="progress-bar"
                        role="progressbar"
                        aria-valuenow="90"
                        aria-valuemin="0"
                        aria-valuemax="100"></div>
                      <span
                        className="popOver"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title="90%"></span>
                    </div>
                  </div>
                </div>
                <div className="progrList">
                  <h4 className="priceName d-flex justify-content-between">
                    <label>Users Leave </label>
                    <span>0.00</span>
                  </h4>
                  <div className="d-flex">
                    <div className="progress prog_ress">
                      <div
                        className="progress-bar"
                        role="progressbar"
                        aria-valuenow="17"
                        aria-valuemin="0"
                        aria-valuemax="100"></div>
                      <span
                        className="popOver"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title="17%"></span>
                    </div>
                  </div>
                </div>
                <div className="progrList">
                  <h4 className="priceName d-flex justify-content-between">
                    <label>Total Linked Accounts</label>
                    <span>0.00</span>
                  </h4>
                  <div className="d-flex">
                    <div className="progress prog_ress">
                      <div
                        className="progress-bar"
                        role="progressbar"
                        aria-valuenow="7"
                        aria-valuemin="0"
                        aria-valuemax="100"></div>
                      <span
                        className="popOver"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title="7%"></span>
                    </div>
                  </div>
                </div>
                <div className="progrList">
                  <h4 className="priceName d-flex justify-content-between">
                    <label></label>
                    <span>0.00</span>
                  </h4>
                  <div className="progress prog_ress">
                    <div
                      className="progress-bar"
                      role="progressbar"
                      aria-valuenow="20"
                      aria-valuemin="0"
                      aria-valuemax="100"></div>
                    <span
                      className="popOver"
                      data-toggle="tooltip"
                      data-placement="bottom"
                      title="20%"></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </>
  );
};


