/** @format */

import { GetApi, PostApi, UpdateApi } from "./index";

class BookingService {
  findVehicle(data) {
    return PostApi(`/booking`, data);
  }
  createBook(data, payment) {
    return PostApi(`/booking/createBook`, data);
  }
  getRides() {
    return GetApi(`/booking/rides`);
  }
  cancelBooking(id,status) {
    return UpdateApi(`/booking/cancel`, { bookingId:id, status });
  }
}

const bookingService = new BookingService();

export default bookingService;
