/** @format */

import { Field, FieldArray, Formik, Form } from "formik";
import React, { useRef, useState } from "react";
import { useEffect } from "react";
import { AiOutlineMinus } from "react-icons/ai";
import { IoMdAdd, IoMdLock } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { Login } from "../../Auth/Login";
import { ReactToastSuccess } from "../../blocks/Modals/ReactToast";
import StripeContainer from "../../Payments/StripeContainer";
import { setPassenger } from "./VehicleSlice";

const FinalDetails = (props) => {
  const [allPassenger, setAllPassenger] = useState(0);
  const dispatch = useDispatch();
  const [showItem, setShowItem] = useState(false);

  const { curruntVehicle, searchVehicle, user, passengerData } = useSelector(
    (state) => {
      const curruntVehicle = state.vehicle.curruntVehicle;
      const searchVehicle = state.vehicle.searchVehicle;
      const user = state.auth.user;
      const passengerData = state.vehicle.passengersData;
      return {
        curruntVehicle,
        searchVehicle,
        user,
        passengerData,
      };
    }
  );


  useEffect(() => {
    let childPassengers = 0;
    searchVehicle?.childSeat.map((s) => {
      childPassengers += Number(s.seat);
    });
    let AllPassengers = searchVehicle?.Passengers + childPassengers;
    if (AllPassengers) {
      setAllPassenger(AllPassengers);
    }
  }, [curruntVehicle, searchVehicle]);

  

  const formikRef = useRef();
  const PassengerSchema = Yup.object().shape({
    Passenger: Yup.array()
      .min(1, "Need at least a Passenger")
      .of(
        Yup.object().shape({
          fname: Yup.string().required("First Name is required"),
          lname: Yup.string().required("Last Name is required"),
          phone: Yup.string().required("Phone Name is required"),
          email: Yup.string()
            .email("Invalid email")
            .required("Email is required"),
        })
      ),
  });

   
  
  const values = {
    Passenger: 
      [
      {
        fname: passengerData?.Passenger[0].fname
          ? passengerData?.Passenger[0].fname
          : "",
        lname: passengerData?.Passenger[0].lname
          ? passengerData?.Passenger[0].lname
          : "",
        phone: passengerData?.Passenger[0].phone
          ? passengerData?.Passenger[0].phone
          : "",
        email: passengerData?.Passenger[0].email
          ? passengerData?.Passenger[0].email
          : "",
      },
    ],
  };



  const handleSubmit = async (values) => {
    dispatch(setPassenger(values));
    ReactToastSuccess("Passenger Add success");
  };

  return (
    <>
      {!user && curruntVehicle ? (
        <Login fake={true} />
      ) : (
        curruntVehicle && (
          <>
            <div className="col-md-6">
              <div className="row detail-info">
                <span className="panel-title ml-2">
                  <span
                    style={{
                      color: "#4a4a4a",
                      fontSize: "14px",
                      fontWeight: "700",
                    }}>
                    Passenger Information
                  </span>
                </span>
              </div>

              <div className="ors-col-max-width">
                <Formik
                  innerRef={formikRef}
                  enableReinitialize={true}
                  initialValues={values}
                  validationSchema={PassengerSchema}
                  onSubmit={(values) => {
                    handleSubmit(values);
                  }}>
                  {({ errors, touched, values }) => (
                    <Form>
                      <div className="form-group">
                        <div className="row">
                          <FieldArray name="Passenger">
                            {({ remove, push }) => (
                              <div>
                                {values.Passenger.length > 0 &&
                                  values.Passenger.map((stops, index) => (
                                    <div className="row mt-2 mb-2" key={index}>
                                      <div className="col-xs-6 col-md-6">
                                        <label for="PickUpDate">
                                          Fist Name
                                        </label>
                                        <div
                                          className="input-group"
                                          id="pickupDateHolder">
                                          <Field
                                            autocomplete="off"
                                            id="PickUpDate"
                                            placeholder="First Name"
                                            name={`Passenger.${index}.fname`}
                                            type="text"
                                            className="The-form-select"
                                          />
                                          {errors?.Passenger?.[index]?.fname &&
                                            touched?.Passenger?.[index]
                                              ?.fname && (
                                              <div className="d-block invalid-feedback">
                                                {errors.Passenger[index].fname}
                                              </div>
                                            )}
                                        </div>
                                      </div>

                                      <div className="col-xs-6 col-md-6">
                                        <label for="PickUpTime">
                                          Last Name
                                        </label>
                                        <div
                                          className="input-group"
                                          id="pickupTimeHolder">
                                          <Field
                                            autocomplete="off"
                                            data-val="true"
                                            placeholder="Last Name"
                                            id="PickUpTime"
                                            name={`Passenger.${index}.lname`}
                                            type="text"
                                            className="The-form-select"
                                          />
                                          {errors?.Passenger?.[index]?.lname &&
                                            touched?.Passenger?.[index]
                                              ?.lname && (
                                              <div className="d-block invalid-feedback">
                                                {errors.Passenger[index].lname}
                                              </div>
                                            )}
                                        </div>
                                      </div>

                                      <div className="col-xs-12 mt-4 col-md-12">
                                        <label for="PickUpTime">
                                          Passenger Phone
                                        </label>
                                        <div
                                          className="input-group"
                                          id="pickupTimeHolder">
                                          <Field
                                            autocomplete="off"
                                            data-val="true"
                                            id="PickUpTime"
                                            placeholder="(555) 555-5555"
                                            name={`Passenger.${index}.phone`}
                                            type="text"
                                            className="The-form-select"
                                          />
                                          {errors?.Passenger?.[index]?.phone &&
                                            touched?.Passenger?.[index]
                                              ?.phone && (
                                              <div className="d-block invalid-feedback">
                                                {errors.Passenger[index].phone}
                                              </div>
                                            )}
                                        </div>
                                      </div>

                                      <div className="col-xs-12 mt-4 col-md-12">
                                        <label for="PickUpTime">
                                          Email Address
                                        </label>
                                        <div
                                          className="input-group"
                                          id="pickupTimeHolder">
                                          <Field
                                            autocomplete="off"
                                            data-val="true"
                                            id="PickUpTime"
                                            name={`Passenger.${index}.email`}
                                            type="text"
                                            className="The-form-select"
                                          />
                                          {errors?.Passenger?.[index]?.email &&
                                            touched?.Passenger?.[index]
                                              ?.email && (
                                              <div className="d-block invalid-feedback">
                                                {errors.Passenger[index].email}
                                              </div>
                                            )}
                                        </div>
                               
                                      </div>
                                      {values.Passenger.length === index + 1 ? (
                                        <button
                                          disabled={
                                            Number(values.Passenger.length) ==
                                            Number(allPassenger)
                                              ? true
                                              : false
                                          }
                                          style={{
                                            fontSize: "13px",
                                            color: "rgb(15 101 228 / 87%)",
                                            background: "transparent",
                                            display: "flex",
                                            justifyContent: "right",
                                            border: "none",
                                          }}
                                          onClick={() =>
                                            push({
                                              fname: "",
                                              lname: "",
                                              phone: "",
                                              email: "",
                                            })
                                          }>
                                          <IoMdAdd size={15} /> Add Passenger
                                        </button>
                                      ) : (
                                        <button
                                          style={{
                                            fontSize: "13px",
                                            color: "rgb(15 101 228 / 87%)",
                                            background: "transparent",
                                            display: "flex",
                                            justifyContent: "right",
                                            border: "none",
                                            marginRight:"10px"
                                          }}
                                          onClick={() => remove()}>
                                          <AiOutlineMinus size={15} /> {" "} Remove
                                          Passenger
                                        </button>
                                      )}
                                    </div>
                                  ))}
                              </div>
                            )}
                          </FieldArray>
                        </div>
                        <ul id="stopsContainer" className="ui-sortable"></ul>
                      </div>

                      <div class="row form-group">
                        <div class="col-md-7">
                          <button
                            id="showRatesBtn"
                            type="submit"
                            class="btn-lg btn-block">
                            Add
                          </button>
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
            <div className="col-md-1 vl"></div>
            <div className="col-md-5">
              <div className="row detail-info">
                <span className="panel-title ml-2">
                  <span
                    style={{
                      color: "#4a4a4a",
                      fontSize: "14px",
                      fontWeight: "700",
                    }}>
                    Pricing
                  </span>
                </span>
              </div>
              <div className="row few-detail-info mt-3">
                <span
                  className="panel-title ml-2"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}>
                  <span
                    style={{
                      color: "#4a4a4a",
                      fontSize: "14px",
                      fontWeight: "500",
                    }}>
                    Total Fee
                  </span>
                  <span>${Math.round(curruntVehicle.fare)}</span>
                </span>
              </div>
              <div className="row total-fare-info">
                <span
                  className="panel-title ml-2"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}>
                  <span
                    style={{
                      color: "#4a4a4a",
                      fontSize: "14px",
                      marginTop: "16px",
                      fontWeight: "500",
                    }}>
                    Total
                  </span>
                  <span
                    style={{
                      color: "#1952c5",
                      fontSize: "36px",
                      fontWeight: "600",
                    }}>
                    ${Math.round(curruntVehicle.fare)}
                  </span>
                </span>
              </div>
              <div className="row detail-info mt-5">
                <span className="panel-title ml-2">
                  <span
                    style={{
                      color: "#4a4a4a",
                      fontSize: "14px",
                      fontWeight: "700",
                    }}>
                    Payment Information
                  </span>
                </span>
              </div>
              <p className="mt-2">
                <IoMdLock /> All transactions are safe and secure.
                <span style={{ color: "#1952c5", cursor: "pointer" }}>
                  Click here
                </span>
                for details.
              </p>

              <div className="row detail-info mt-3">
                <div className="ors-custom-select">
                  <StripeContainer amount={Math.round(curruntVehicle.fare)} />
                </div>
                <span
                  className="field-validation-valid"
                  data-valmsg-for="ServiceTypeId"
                  data-valmsg-replace="true"></span>
              </div>
                      <div>
                        <span></span>
                      </div>
            </div>
          </>
        )
      )}
    </>
  );
};

export default FinalDetails;
