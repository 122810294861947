/** @format */

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import React, { useEffect, useState } from "react";
import paymentService from "../services/paymentService";
import CheckoutForm from "./CheckoutForm";


const stripeTestPromise = loadStripe(process.env.REACT_APP_STRIPE_PK);

export default function StripeContainer(props) {
  
  const [clientSecret, setClientSecret] = useState(null);

  let createPaymentIntent = async () => {
    console.log("createPaymentIntent call");
    let res = await paymentService.createPaymentIntent({
      s132: props.amount,
    });
    if (res.status) {
      setClientSecret(res.clientSecret);
    }
  };

  useEffect(() => {
    createPaymentIntent();
  }, []);

  const appearance = {
    theme: "stripe",
  };
  const options = {
    clientSecret,
    appearance,
  };
  return (
    <>
      {clientSecret && (
        <Elements stripe={stripeTestPromise}  options={options}>
          <CheckoutForm />
        </Elements>
      )}
    </>
  );
}
