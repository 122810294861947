/** @format */

import Dropdown from "react-bootstrap/Dropdown";
import { FaUser } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { LogoutUser } from "../../Auth/authSlice";
import { MdEdit } from "react-icons/md";


export const BasicDropdown = (props) => {
  const dispatch = useDispatch();
    const { user, error, status } = useSelector((state) => state.auth);

  return (
    <Dropdown>
      <Dropdown.Toggle
        variant="trasparent"
        id="dropdown-basic"
        className="text-primary">
        <FaUser />
        <span
          style={{
            color: "rgb(15 101 228 / 87%)",
            cursor: "pointer",
            fontSize: "15px",
            marginLeft: "5px",
          }}>
          {props.name}
        </span>
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <Dropdown.Item href="#">Welcome, {user?.user.fname}</Dropdown.Item>
        <Dropdown.Item href="#">
          Edit Profile <MdEdit />
        </Dropdown.Item>
        <Dropdown.Item href="#" onClick={() => dispatch(LogoutUser())}>
          Logout
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};
