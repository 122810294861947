import React from 'react'
import MainFooter from '../../../components/MainFooter';
import { MainHeader } from '../../../components/MainHeader';
import { NavLink } from "react-router-dom";

export const SfoLimoCarTransfer = () => {
  return (
    <>
      <MainHeader />
      <div id="marine-content-inner">
        <section id="main-content">
          <div className="container">
            <NavLink to="https://www.continentalclubcar.com/reviews">
              <img
                className="rev_btn"
                src="/images/customer-reviews.png"
                width="100"
                height="39"
              />
            </NavLink>
            <div className="page-heading style3 wrapper border-bottom ">
              <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-6">
                  <h1>New York city and Long island Airport Transportation</h1>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6">
                  <p className="breadcrumbs">
                    <span typeof="v:Breadcrumb">
                      <NavLink to="/" property="v:title" rel="v:url">
                        Home
                      </NavLink>
                    </span>
                    <span className="delimiter">|</span>
                    <span className="current">Services</span>
                  </p>
                </div>
              </div>
            </div>
            <div className="row padding-top60">
              <section className="col-lg-12 col-md-12 col-sm-12">
                <div className="row">
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="flexslider flexslider-thumbnail-gallery ">
                      <ul className="slides">
                        <li
                          className="flex-active-slide"
                          style={{
                            width: "100%",
                            float: "left",
                            marginRight: "-100%",
                            position: "relative",
                            opacity: 1,
                            display: "block",
                            zIndex: "2",
                          }}>
                          <img
                            alt=""
                            src="/images/sfo-transportation.jpeg"
                            draggable="false"
                          />
                        </li>
                      </ul>
                      <ol className="flex-control-nav flex-control-thumbs"></ol>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <h2
                      className="special-text wow animated fadeInDown services-special-h3 animated"
                      data-animation="fadeInDown"
                      style={{
                        visibility: "visible",
                        animationName: "fadeInDown",
                      }}>
                      Superb NYC & LI Car Services
                    </h2>
                    <p>
                      You can count on CONTINENTAL CLUB CAR for the best in
                      Luxury San Francisco Airport ground transportation. Choose
                      your style of NYC & LI airport limousine service -- SUV,
                      sedan, or town car. Our NYC & LI airport transfer service
                      fleet includes elegant black vehicles from Lincoln,
                      Mercedes, BMW, and Chevrolet with lush interiors and all
                      the amenities. Or choose a 10-passenger NYC & LI Airport
                      limousine. Our experienced staff will schedule your
                      reservation and your skilled, experienced, and highly
                      professional chauffeur will keep you right on schedule.
                    </p>
                    <h2
                      className="special-text wow animated fadeInDown services-special-h3 animated"
                      data-animation="fadeInDown"
                      style={{
                        visibility: "visible",
                        animationName: "fadeInDown",
                      }}>
                      NYC & LI Car Service
                    </h2>
                    <p>
                      Avoid the sprawling San Francisco Airport ground
                      transportation's complications, delays, and lengthy
                      shuttle rides. With a NYC & LI airport transfer service
                      you'll enjoy a private vehicle's plush interiors and
                      conveniences at not that much greater expense than a
                      public taxi ride. For arrivals, let our NYC & LI airport
                      limo services escort you from baggage claim to nearby
                      hotels, or into The City for major hotels, businesses, and
                      event venues. For departures our experienced NYC & LI car
                      service experts will dependably get you to the right
                      terminal, right on time.
                    </p>
                    <p>
                      Black Car's NYC & LI airport transfer service also covers
                      San Jose (SJC) and Oakland (OAK) International Airports.
                      We're happy to accommodate transfers between those hubs,
                      as well as NYC & LI car service to regional, municipal,
                      and private airports. Our door-to-door services make
                      complex travel arrangements simple again.
                    </p>
                    <NavLink
                      className="button biggest"
                      to="/club-limousine-reservations"
                      target="_self">
                      BOOK NOW
                    </NavLink>
                  </div>
                </div>
                <div className="clearfix clearfix-height30"></div>
                <div className="row row-fluid padding-bottom20">
                  <h2 align="center" style={{ fontWeight: "700" }}>
                    Our{" "}
                    <img
                      src="/images/five-stars.png"
                      width="200"
                      height="118"
                    />{" "}
                    Reviews &amp; Testimonials
                  </h2>
                </div>
                <div
                  className="full_bg full-width-bg marine-header2-full_bg3"
                  data-animation="">
                  <h3
                    className="special-text wow animated fadeInDown marine-header2-h3"
                    data-animation="fadeInDown"
                    style={{
                      visibility: "visible",
                      animationName: "fadeInLeft",
                    }}>
                    NYC & LI Luxury Transportation
                  </h3>
                  <div
                    className="special-text wow animated fadeInLeft marine-header2-special-text1"
                    data-animation="fadeInLeft"
                    style={{
                      visibility: "visible",
                      animationName: "fadeInLeft",
                    }}>
                    Black Car's East Bay limo deals are perfect for corporate
                    travel around town, including dining, event, and
                    entertainment venues.
                    <br />
                    Call CONTINENTAL CLUB CAR Luxury Transportation for all Your
                    east Bay area limo service needs.{" "}
                    <img
                      alt=""
                      className="aligncenter dont_scale wow animated fadeInDown"
                      data-animation="fadeInDown"
                      src="/images/la-limo-rentals.png"
                      style={{
                        visibility: "visible",
                        animationName: "fadeInLeft",
                      }}
                    />
                    <div className="clearfix"></div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </section>
      </div>
      <MainFooter />
    </>
  );
}