/** @format */

import React, { useEffect, useRef, useState } from "react";
import MainFooter from "../../components/MainFooter";
import { MainHeader } from "../../components/MainHeader";
import { DirectionsRenderer, GoogleMap } from "@react-google-maps/api";
import { IoMdAdd } from "react-icons/io";
import { BiRadioCircle } from "react-icons/bi";
import { RiEditBoxLine } from "react-icons/ri";
import { AiOutlineMinus } from "react-icons/ai";
import { HiUsers } from "react-icons/hi";
import { ImLocation } from "react-icons/im";
import { BsFillBagFill, BsBoxArrowInLeft } from "react-icons/bs";
import { MdDelete, MdModeEditOutline } from "react-icons/md";
import { Formik, Form, Field, FieldArray } from "formik";
import * as Yup from "yup";
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import PickupAddress from "../../blocks/Picker/PickupAddress";
import DropoffAddress from "../../blocks/Picker/DropoffAddress";
import bookingService from "../../services/BookingService";
import { FullLoader } from "../../blocks/Loaders/FullLoader";
import FinalDetails from "./FinalDetails";
import SelectVehicle from "./SelectVehicle";
import {removePassenger, removeSearch,isSearch,removeSelect,searchNewVehicle, removeVehicleCurruntSelected} from "./VehicleSlice";
import { ReactToast } from "../../blocks/Modals/ReactToast";

const BookVehicle = () => {

    const { searchVehicle, Search } = useSelector((state) => {
      const searchVehicle = state.vehicle.searchVehicle;
      const Search = state.vehicle.isSearch;
      return {
        searchVehicle,
        Search,
      };
    });

  const formikRef = useRef();
  const dispatch = useDispatch();
  const [map, setMap] = React.useState(null);
  const [loader, setLoader] = useState(false);
  const [cars, setCars] = useState([]);
  const [display, setDisplay] = useState("");
  const [state, setState] = useState(null);
  const [mainState, setMainState] = useState({
    pickupLat: "",
    pickupLng: "",
    dropoffLat: "",
    dropoffLng: "",
  });

  const LoginSchema = Yup.object().shape({
    pickup_address: Yup.string().required("Pickup location is required"),
    dropoff_address: Yup.string().required("Dropoff location is required"),
    pickup_date: Yup.string().required("Pickup Date is required"),
    pickup_time: Yup.string().required("Pickup Time is required"),
  });


  const values = {
    ServiceTypeId: "204531",
    pickup_date: searchVehicle?.pickup_date ? searchVehicle?.pickup_date : "",
    pickup_time: searchVehicle?.pickup_time ? searchVehicle?.pickup_time : "",
    pickup_address: searchVehicle?.pickup_address ? searchVehicle?.pickup_address : "",
    pickup_lat: searchVehicle?.pickup_lat ? searchVehicle?.pickup_lat : "",
    pickup_lng: searchVehicle?.pickup_lng ? searchVehicle?.pickup_lng : "",
    dropoff_address: searchVehicle?.dropoff_address ? searchVehicle?.dropoff_address : "",
    dropoff_lng:searchVehicle?.dropoff_lng ? searchVehicle?.dropoff_lng : "",
    dropoff_lat: searchVehicle?.dropoff_lat ? searchVehicle?.dropoff_lat : "",
    Passengers:searchVehicle?.Passengers ? searchVehicle?.Passengers : 1,
    Luggage: searchVehicle?.Luggage ? searchVehicle?.Luggage : 0,
    stops: [],
    childSeat: [],
  };

  useEffect(() => {
    if (searchVehicle) {
      dispatch(removeVehicleCurruntSelected())
      dispatch(removeSelect())
    }
  },[])

  const handleSubmit = async (values) => {
    setLoader(true);
    let res = await bookingService.findVehicle(values);
    if (res.status && res.result.length > 0) {
      setCars(res.result);
      dispatch(searchNewVehicle(values));
      dispatch(isSearch());
      setLoader(false);
    } else {
      setLoader(false);
      ReactToast("No rates found.");
    }
  };

  const containerStyle = {
    width: "100%",
    height: "450px",
  };

  const center = {
    lat: 40.6967444,
    lng: -74.5210816
  };

  // const onLoad = React.useCallback(function callback(map) {
  //   // This is just an example of getting and using the map instance!!! don't just blindly copy!
  //   const bounds = new window.google.maps.LatLngBounds(center);
  //   map.fitBounds(bounds);
  //   setMap(map);
  // }, []);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  const getPickupLatLng = (pickupLat, pickupLng) => {
    setMainState({ ...mainState, pickupLat, pickupLng });
  };

  const getDropoffLatLng = (dropoffLat, dropoffLng) => {
    setMainState({ ...mainState, dropoffLat, dropoffLng });
  };

  useEffect(() => {
    if (formikRef.current) {
      formikRef.current.setFieldValue("pickup_lat",searchVehicle?.pickup_lat ? searchVehicle?.pickup_lat : mainState.pickupLat);
      formikRef.current.setFieldValue("pickup_lng",searchVehicle?.pickup_lng ? searchVehicle?.pickup_lng : mainState.pickupLng);
      formikRef.current.setFieldValue("dropoff_lat", searchVehicle?.dropoff_lat ? searchVehicle?.dropoff_lat : mainState.dropoffLat);
      formikRef.current.setFieldValue("dropoff_lng", searchVehicle?.dropoff_lng ? searchVehicle?.dropoff_lng : mainState.dropoffLng);
    }
  }, [
    mainState.pickupLat,
    mainState.pickupLng,
    mainState.dropoffLat,
    mainState.dropoffLng,
  ]);

  const DirectionsService = new window.google.maps.DirectionsService();
  useEffect(() => {
    console.log(mainState.pickupLat.length,"mainlen")
      mainState.pickupLat != "" &&
        mainState.pickupLng != "" &&
        mainState.dropoffLat != "" &&
        mainState.dropoffLng != "" &&
        DirectionsService.route(
          {
            origin: new window.google.maps.LatLng(
              mainState.pickupLat,
              mainState.pickupLng
            ),
            destination: new window.google.maps.LatLng(
              mainState.dropoffLat,
              mainState.dropoffLng
            ),
            travelMode: window.google.maps.TravelMode.DRIVING,
          },
          (result, status) => {
            if (status === window.google.maps.DirectionsStatus.OK) {
              setState(result);
            } else {
              console.error(`error fetching directions ${result}`);
            }
          }
        );
  }, [mainState.dropoffLng, mainState.pickupLat]);


  return (
    <>
      {loader && <FullLoader />}
      <MainHeader />
      <div className="container mt-5">
        <div className="page-heading style3 wrapper border-bottom ">
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-6">
              <h1>Reservations</h1>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-6">
              <p className="breadcrumbs">
                <span typeof="v:Breadcrumb">
                  <NavLink to="/" property="v:title" rel="v:url">
                    Home
                  </NavLink>
                </span>
                <span className="delimiter">|</span>
                <span className="current">Limo Quote</span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="container find-vehicle-container">
        <header id="top" className="ors-menu-top">
          <nav className="navbar">
            <div className="container-fluid mt-3" id="accountInformationHeader">
              <ul className="nav navbar-nav pull-left">
                <li>
                  <div
                    style={{
                      color: "rgb(15 101 228 / 87%)",
                      cursor: "pointer",
                      fontSize: "15px",
                      marginLeft: "5px",
                    }}
                    className="ml-0">
                    <RiEditBoxLine color="" size={18} />
                    <NavLink
                      to="/club-limousine-reservations"
                      className="ml-1">
                      New Reservation
                    </NavLink>
                  </div>
                </li>
              </ul>

              <div className="nav navbar-nav navbar-right">
                <div
                  className="dropdown ors-btn-login-outer ors-login-outer"
                  id="dropdownLoginOuter"
                  onkeypress="Ores4Accounts.OnLoginForm1KeyPress(event)">
                  <NavLink to="/login">
                    <span
                      className="ors-btn-login"
                      id="holderLoginForm"
                      style={{
                        color: "rgb(15 101 228 / 87%)",
                        cursor: "pointer",
                        fontSize: "15px",
                        marginLeft: "5px",
                      }}>
                      <BsBoxArrowInLeft size={17} /> Log in
                    </span>
                  </NavLink>
                  {/* <div
                    aria-labelledby="holderLoginForm"
                    className="dropdown-menu tab-content ors-popup-static"
                    role="menu">
                    <div className="button-holder text-right">
                      <button className="btn btn-outline btn-sm">Close</button>
                    </div>
                    <div
                      role="tabpanel"
                      className="tab-pane active"
                      id="formLogin">
                      <form
                        id="accountLoginForm1"
                        role="form"
                        novalidate="novalidate">
                        <div className="form-group m-b-0">
                          <div className="row m-b-sm">
                            <div className="col-sm-5">
                              <label for="username-login" className="sr-only">
                                Email Address / Username
                              </label>
                              <input
                                autocomplete="off"
                                className="form-control input-sm"
                                data-val="true"
                                data-val-required="Username required"
                                id="username-login"
                                name="Username"
                                placeholder="Email Address / Username"
                                tabindex="1"
                                type="text"
                                value=""
                              />
                            </div>
                            <div className="col-sm-5">
                              <label for="password-login" className="sr-only">
                                Password
                              </label>
                              <input
                                autocomplete="off"
                                className="form-control input-sm"
                                data-val="true"
                                data-val-required="Password required"
                                id="password-login"
                                name="Password"
                                placeholder="Password"
                                tabindex="2"
                                type="password"
                                value=""
                              />
                              <div
                                className="text-danger"
                                style={{ display: "none" }}
                                id="incorrect-credentials-message">
                                Username or/and Password are incorrect
                              </div>
                            </div>
                            <div className="col-sm-2">
                              <button
                                type="button"
                                tabindex="3"
                                onclick="Ores4Accounts.OnLoginNowClick('headerBtn',this);"
                                className="btn btn-primary btn-sm  btn-block">
                                Log in
                              </button>
                            </div>
                          </div>

                          <div className="row center-sm">
                            <div className="col-sm-5">
                              <div className="">
                                Don’t have an account?
                                <NavLink to="/register">Register Now</NavLink>
                              </div>
                            </div>
                            <div className="col-sm-5">
                              <div role="tablist">
                                <span
                                  className="text-link"
                                  data-target="#formResetPassword1"
                                  aria-controls="home"
                                  role="tab"
                                  data-toggle="tab">
                                  Forgot password?
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>

                    <div
                      role="tabpanel"
                      className="tab-pane"
                      id="formResetPassword1">
                      <form role="form">
                        <div className="form-reset-pass">
                          <div className="form-reset-pass-input">
                            <label
                              for="email-address-forgot-pass1"
                              className="sr-only">
                              Email Address
                            </label>
                            <input
                              type="email"
                              className="form-control input-sm"
                              id="email-address-forgot-pass1"
                              placeholder="Email Address"
                            />
                            <div
                              className="text-danger"
                              style={{ display: "none" }}
                              id="error_incorrectAccountEmail1">
                              Please enter a valid email address
                            </div>
                            <div
                              className="text-danger"
                              style={{ display: "none" }}
                              id="error_sendingFaliled1">
                              Sending failed
                            </div>
                            <div role="tablist" className="hidden-xs">
                              Return to
                              <span
                                className="text-link"
                                data-target="#formLogin"
                                aria-controls="home"
                                role="tab"
                                data-toggle="tab">
                                Log in
                              </span>
                            </div>
                          </div>
                          <div
                            className="form-reset-pass-message"
                            style={{ display: "none" }}
                            id="success_accountInfoSent1">
                            <div className="form-reset-pass-message-succes">
                              Email has been sent to
                            </div>
                            <div className="hidden-xs">
                              Return to
                              <span
                                className="text-link"
                                data-target="#formLogin"
                                aria-controls="home"
                                role="tab"
                                data-toggle="tab">
                                Log in
                              </span>
                            </div>
                          </div>
                          <div className="form-reset-pass-button">
                            <button
                              type="button"
                              onclick="Ores4Accounts.resetPassword(1, this);"
                              className="btn btn-primary btn-sm btn-block">
                              Reset Password
                            </button>
                          </div>
                          <div className="form-reset-return-button-mob">
                            <span
                              className="btn btn-outline btn-sm btn-block"
                              data-target="#formLogin"
                              aria-controls="home"
                              role="tab"
                              data-toggle="tab">
                              Return to Log In
                            </span>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </nav>
        </header>
        <div className="container">
          <div className="row ride-info">
            <span className="panel-title select-btn ml-2">
              <span
                onClick={() => setDisplay("")}
                style={{
                  color: "rgb(15 101 228 / 87%)",
                  cursor: "pointer",
                  "font-size": "14px",
                  "font-weight": "500",
                }}>
                <BiRadioCircle size={20} /> Step 1: Ride Info
              </span>
              {Search && (
                <span
                  onClick={() => {
                    setCars([]);
                    dispatch(removeSearch());
                    dispatch(removeSelect());
                  }}
                  style={{
                    color: "rgb(74, 74, 74)",
                    cursor: "pointer",
                    fontSize: "14px",
                    fontWeight: "600",
                  }}>
                  <NavLink to="#">
                    <MdModeEditOutline size={17} /> Edit
                  </NavLink>
                </span>
              )}
            </span>
          </div>
        </div>

        {Search && searchVehicle ? (
          <div className="row p-3">
            <div className={`col-md-5 mt-1`}>
              <h5 className="mt-2">
                {searchVehicle.pickup_date} {searchVehicle.pickup_time}
              </h5>
              <span className="mt-2">
                Passenger: {searchVehicle.Passengers}
              </span>
            </div>
            <div className={`col-md-6 mt-1`}>
              <p>
                <ImLocation /> {searchVehicle.pickup_address}
              </p>
              <p>
                <ImLocation /> {searchVehicle.dropoff_address}
              </p>
            </div>
          </div>
        ) : (
          <div className={`${display} row p-3`}>
            <div className={`col-md-6 ${display}`}>
              <div className="ors-col-max-width">
                <Formik
                  innerRef={formikRef}
                  initialValues={values}
                  enableReinitialize={true}
                  validationSchema={LoginSchema}
                  onSubmit={(values) => {
                    handleSubmit(values);
                  }}>
                  {({ errors, touched, setFieldValue, values }) => (
                    <Form>
                      <div className="form-group">
                        <label for="ServiceTypeId">Select Service Type</label>
                        <div className="ors-custom-select">
                          <Field
                            as="select"
                            className="The-form-select"
                            id="ServiceTypeId"
                            name="ServiceTypeId">
                            <option selected="selected" value="204531">
                              {" "}
                              From Airport- Curb side{" "}
                            </option>
                            <option value="116267">
                              From Airport - Inside terminal To: Airport
                            </option>
                            <option>Point-A to Point-B</option>
                            <option value="116268">
                              Hourly chuffer service
                            </option>
                          </Field>
                        </div>
                        <span
                          className="field-validation-valid"
                          data-valmsg-for="ServiceTypeId"
                          data-valmsg-replace="true"></span>
                      </div>

                      <div className="form-group">
                        <div className="row">
                          <div className="col-xs-6 col-md-7">
                            <label for="PickUpDate">Pick-Up Date</label>
                            <div className="input-group" id="pickupDateHolder">
                              <Field
                                autocomplete="off"
                                id="PickUpDate"
                                name="pickup_date"
                                type="date"
                                className={`The-form-select  ${
                                  touched.pickup_date && errors.pickup_date
                                    ? "is-invalid form-control"
                                    : "The-form-select"
                                }`}
                              />
                              {errors.pickup_date && touched.pickup_date ? (
                                <div className="d-block invalid-feedback mt-n4 ml-3">
                                  {errors.pickup_date}
                                </div>
                              ) : null}
                            </div>
                          </div>

                          <div className="col-xs-6 col-md-5">
                            <label for="PickUpTime">Pick-Up Time</label>
                            <div className="input-group" id="pickupTimeHolder">
                              <Field
                                autocomplete="off"
                                data-val="true"
                                id="PickUpTime"
                                name="pickup_time"
                                type="time"
                                className={`The-form-select  ${
                                  touched.pickup_time && errors.pickup_time
                                    ? "is-invalid form-control"
                                    : "The-form-select"
                                }`}
                              />
                              {errors.pickup_time && touched.pickup_time ? (
                                <div className="d-block invalid-feedback mt-n4 ml-3">
                                  {errors.pickup_time}
                                </div>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="form-group">
                        <div className="row">
                          <div className="col-xs-12 col-md-12">
                            <label for="pick-up-Location">
                              Pick-Up Location
                            </label>
                            <div className="input-group" id="pickupDateHolder">
                              <Field
                                component={PickupAddress}
                                getPickupLatLng={getPickupLatLng}
                                autocomplete="off"
                                name="pickup_address"
                                id="pickupaddress"
                                className={`The-form-select  ${
                                  touched.pickup_address &&
                                  errors.pickup_address
                                    ? "is-invalid form-control"
                                    : "The-form-select"
                                }`}
                              />
                              {errors.pickup_address &&
                              touched.pickup_address ? (
                                <div className="d-block invalid-feedback mt-n4 ml-3">
                                  {errors.pickup_address}
                                </div>
                              ) : null}
                              <Field name="pickup_lat" type="hidden" />
                              <Field name="pickup_lng" type="hidden" />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="form-group">
                        <div className="row">
                          <FieldArray name="stops">
                            {({ remove, push }) => (
                              <div>
                                <button
                                  className=""
                                  disabled={
                                    values.stops.length > 0 ? true : false
                                  }
                                  style={{
                                    fontSize: "13px",
                                    color: "rgb(15 101 228 / 87%)",
                                    background: "transparent",
                                    border: "none",
                                  }}
                                  onClick={() => push({ name: "" })}>
                                  <IoMdAdd /> Add Stop
                                </button>
                                {values.stops.length > 0 &&
                                  values.stops.map((stops, index) => (
                                    <div className="row" key={index}>
                                      <div className="col-xs-10 col-md-10 mt-2">
                                        <div
                                          className="input-group"
                                          id="pickupTimeHolder">
                                          <Field
                                            autocomplete="off"
                                            data-val="true"
                                            id="PickUpTime"
                                            name={`stops.${index}.name`}
                                            type="text"
                                            placeholder="Add stop"
                                            class="The-form-select pac-target-input"
                                          />
                                        </div>
                                      </div>
                                      <div className="col-1 mt-3">
                                        <MdDelete
                                          onClick={() => remove(index)}
                                          size={20}
                                        />
                                      </div>
                                    </div>
                                  ))}
                              </div>
                            )}
                          </FieldArray>
                        </div>
                        <ul id="stopsContainer" className="ui-sortable"></ul>
                      </div>

                      <div className="form-group">
                        <div className="row">
                          <div className="col-xs-12 col-md-12">
                            <label for="PickUpDate">Drop-Off Location</label>
                            <div className="input-group" id="pickupDateHolder">
                              <Field
                                component={DropoffAddress}
                                getDropoffLatLng={getDropoffLatLng}
                                name="dropoff_address"
                                id="dropoff_address"
                                autocomplete="off"
                                placeholder="Your drop-off location"
                                className={`The-form-select  ${
                                  touched.dropoff_address &&
                                  errors.dropoff_address
                                    ? "is-invalid form-control"
                                    : "The-form-select"
                                }`}
                              />
                              {errors.dropoff_address &&
                              touched.dropoff_address ? (
                                <div className="d-block invalid-feedback mt-n4 ml-3">
                                  {errors.dropoff_address}
                                </div>
                              ) : null}
                              <FieldArray />
                              <Field name="dropoff_lat" type="hidden" />
                              <Field name="dropoff_lng" type="hidden" />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="form-group">
                        <div className="row">
                          <div class="col-md-6">
                            <label for="PassengerNumber">
                              Number of Passengers
                            </label>
                            <div class="input-group bootstrap-touchspin border">
                              <span class="input-group-btn">
                                <button
                                  class="btn btn-default bootstrap-touchspin-down"
                                  type="button">
                                  <BsFillBagFill />
                                </button>
                              </span>
                              <span class="input-group-btn">
                                <button
                                  tabindex="59"
                                  class="btn btn-default bootstrap-touchspin-down"
                                  type="button"
                                  onClick={() => {
                                    setFieldValue(
                                      "Passengers",
                                      values.Passengers > 1
                                        ? values.Passengers - 1
                                        : 1
                                    );
                                  }}>
                                  <AiOutlineMinus />
                                </button>
                              </span>
                              <Field
                                class="form-control"
                                id="PassengerNumber"
                                name="Passengers"
                                type="number"
                                style={{ display: "block" }}
                              />
                              <span class="input-group-btn">
                                <button
                                  tabindex="61"
                                  class="btn btn-default bootstrap-touchspin-up"
                                  type="button"
                                  onClick={() => {
                                    setFieldValue(
                                      "Passengers",
                                      values.Passengers + 1
                                    );
                                  }}>
                                  <IoMdAdd />
                                </button>
                              </span>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <label for="PassengerNumber">Luggage Count</label>
                            <div class="input-group bootstrap-touchspin border">
                              <span class="input-group-btn">
                                <button
                                  tabindex="59"
                                  class="btn btn-default bootstrap-touchspin-down"
                                  type="button">
                                  <HiUsers />
                                </button>
                              </span>
                              <span class="input-group-btn">
                                <button
                                  tabindex="59"
                                  class="btn btn-default bootstrap-touchspin-down"
                                  type="button"
                                  onClick={() => {
                                    setFieldValue(
                                      "Luggage",
                                      values.Luggage > 0
                                        ? values.Luggage - 1
                                        : 0
                                    );
                                  }}>
                                  <AiOutlineMinus />
                                </button>
                              </span>
                              <Field
                                class="form-control"
                                id="PassengerNumber"
                                name="Luggage"
                                type="number"
                                style={{ display: "block" }}
                              />

                              <span class="input-group-btn">
                                <button
                                  tabindex="61"
                                  class="btn btn-default bootstrap-touchspin-up"
                                  type="button"
                                  onClick={() => {
                                    setFieldValue(
                                      "Luggage",
                                      values.Luggage + 1
                                    );
                                  }}>
                                  <IoMdAdd />
                                </button>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="form-group mt-4">
                        <div className="m-b-sm">
                          <FieldArray name="childSeat">
                            {({ remove, push }) => (
                              <div>
                                <button
                                  type="button"
                                  className="mt-4"
                                  disabled={
                                    values.childSeat.length > 2 ? true : false
                                  }
                                  style={{
                                    fontSize: "13px",
                                    color: "rgb(15 101 228 / 87%)",
                                    background: "transparent",
                                    border: "none",
                                  }}
                                  onClick={() =>
                                    push({ type: "Infant (ages 0-1)", seat: 1 })
                                  }>
                                  <IoMdAdd /> Add Child Seat
                                </button>
                                {values.childSeat.length > 0 &&
                                  values.childSeat.map((stops, index) => (
                                    <div className="row mt-3" key={index}>
                                      <div className="col-5 mr-0">
                                        <div className="ors-custom-select">
                                          <Field
                                            as="select"
                                            className="The-form-select"
                                            id="ServiceTypeId"
                                            name={`childSeat.${index}.type`}>
                                            <option
                                              selected="selected"
                                              value="Infant (ages 0-1)">
                                              Infant (ages 0-1)
                                            </option>
                                            <option value="Toddler Seat (ages 1-3)">
                                              Toddler Seat (ages 1-3)
                                            </option>
                                            <option value="Booster Seat (ages 3-6)">
                                              Booster Seat (ages 3-6)
                                            </option>
                                          </Field>
                                        </div>
                                      </div>
                                      <div className="col-6">
                                        <div class="input-group bootstrap-touchspin border">
                                          <span class="input-group-btn">
                                            <button
                                              class="btn btn-default bootstrap-touchspin-down"
                                              type="button"
                                              onClick={() => {
                                                setFieldValue(
                                                  `childSeat${[index]}seat`,
                                                  values["childSeat"][index]
                                                    .seat > 1
                                                    ? (values["childSeat"][
                                                        index
                                                      ].seat =
                                                        values["childSeat"][
                                                          index
                                                        ].seat - 1)
                                                    : 1
                                                );
                                              }}>
                                              <AiOutlineMinus />
                                            </button>
                                          </span>
                                          <Field
                                            class="form-control"
                                            id="PassengerNumber"
                                            placeholder="1"
                                            name={`childSeat${[index]}seat`}
                                            type="number"
                                            style={{ display: "block" }}
                                          />
                                          <span class="input-group-btn">
                                            <button
                                              class="btn btn-default bootstrap-touchspin-up"
                                              type="button"
                                              onClick={() => {
                                                setFieldValue(
                                                  `childSeat${[index]}seat`,
                                                  (values["childSeat"][
                                                    index
                                                  ].seat =
                                                    values["childSeat"][index]
                                                      .seat + 1)
                                                );
                                              }}>
                                              <IoMdAdd />
                                            </button>
                                          </span>
                                        </div>
                                      </div>

                                      <div className="col-1 mt-2">
                                        <MdDelete
                                          onClick={() => remove(index)}
                                          size={20}
                                        />
                                      </div>
                                    </div>
                                  ))}
                              </div>
                            )}
                          </FieldArray>
                        </div>
                      </div>

                      <div class="row form-group">
                        <div class="col-md-7">
                          <button
                            id="showRatesBtn"
                            type="submit"
                            class="btn-lg btn-block"
                            onClick={() => window.scrollTo(0, 720)}>
                            Select Vehicle
                          </button>
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
            <div className={`col-md-6 mt-4 ${display}`}>
              {
                <GoogleMap
                  mapContainerStyle={containerStyle}
                  center={center}
                  zoom={8}
                  onUnmount={onUnmount}>
                  {mainState.pickupLat === "" &&
                  mainState.dropoffLat === "" ? null : (
                    <>
                      <DirectionsRenderer directions={state} />
                    </>
                  )}
                </GoogleMap>
              }
            </div>
          </div>
        )}

        <SelectVehicle cars={cars} />
        <div className="container">
          <div className="row mt-3 ride-info">
            <span className="panel-title ml-2">
              <span
                style={{
                  color: "rgb(15 101 228 / 87%)",
                  cursor: "pointer",
                  "font-size": "14px",
                  "font-weight": "500",
                }}>
                <BiRadioCircle size={20} /> Step 3: Final Details
              </span>
            </span>
          </div>
          <div class="row final-details mt-3">
            <FinalDetails />
          </div>
        </div>
      </div>
      <div className="row row-fluid padding-bottom20 mt-4">
        <h2 align="center" style={{ fontWeight: "700" }}>
          Our <img src="/images/five-stars.png" width="200" height="118" />
          Reviews &amp; Testimonials
        </h2>
      </div>

      <MainFooter />
    </>
  );
};

export default BookVehicle;
