import React from 'react'
import MainFooter from '../../../components/MainFooter';
import { MainHeader } from '../../../components/MainHeader';
import { NavLink } from "react-router-dom";

const SonomaWineTour = () => {
  return (
    <>
      <MainHeader />
      <div id="marine-content-inner">
        <section id="main-content">
          <div className="container">
            <NavLink to="/reviews">
              <img
                className="rev_btn"
                src="/images/customer-reviews.png"
                width="100"
                height="39"
              />
            </NavLink>
            <div className="page-heading style3 wrapper border-bottom ">
              <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-6">
                  <h1>Sonoma Wine Tours</h1>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6">
                  <p className="breadcrumbs">
                    <span typeof="v:Breadcrumb">
                      <NavLink to="/" property="v:title" rel="v:url">
                        Home
                      </NavLink>
                    </span>
                    <span className="delimiter">|</span>
                    <span className="current">Services</span>
                  </p>
                </div>
              </div>
            </div>
            <div className="row padding-top60">
              <section className="col-lg-12 col-md-12 col-sm-12">
                <div className="row">
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="flexslider flexslider-thumbnail-gallery ">
                      <ul className="slides">
                        <li
                          className="flex-active-slide"
                          style={{
                            width: "100%",
                            float: "left",
                            marginRight: "-100%",
                            position: "relative",
                            opacity: 1,
                            display: "block",
                            zIndex: "2",
                          }}>
                          <img
                            alt=""
                            src="/images/suv2.jpeg"
                            draggable="false"
                          />
                        </li>
                      </ul>
                      <ol className="flex-control-nav flex-control-thumbs"></ol>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <h2
                      className="special-text wow animated fadeInDown services-special-h3 animated"
                      data-animation="fadeInDown"
                      style={{
                        visibility: "visible",
                        animationName: "fadeInDown",
                      }}>
                      Luxurious Sonoma Valley Limo Tours
                    </h2>
                    <p>
                      Whenever you think about wine tours for the bay area,
                      think about taking the experience to a whole new level
                      with Sonoma Valley group transportation in a luxurious and
                      elegant black limousine or town car. Treat yourself and
                      impress your guests with the best in Sonoma Vineyards
                      transportation at surprisingly reasonable prices.
                    </p>
                    <h2
                      className="special-text wow animated fadeInDown services-special-h3 animated"
                      data-animation="fadeInDown"
                      style={{
                        visibility: "visible",
                        animationName: "fadeInDown",
                      }}>
                      Wine Tour Limousine
                    </h2>
                    <p>
                      With our Sonoma Valley limo tours you'll spend the entire
                      day in comfort and posh surroundings, transforming your
                      wine tasting into a unique, memorable, and elegant
                      experience. There's nothing like a sleek black sedan, town
                      car, or stretch limousine for the utmost in Sonoma
                      vineyards transportation. For setting a special mood, you
                      can even choose a fully equipped top-end SUV.
                    </p>
                    <p>
                      With a sharply uniformed chauffeur your day takes on added
                      sophistication while you enjoy amenities like a superb
                      sound system and real glassware to let your wine tours bay
                      area continue uninterrupted throughout the day and on into
                      the evening. With professional Sonoma Valley group
                      transportation mere travel and taking in the mountain
                      vineyard scenery becomes a truly remarkable experience.
                      You can count on CONTINENTAL CLUB CAR for picture-perfect Sonoma
                      Valley limo tours. Our vehicles are flawlessly maintained
                      and our chauffeurs are truly professionals, among the best
                      in the business. We can comfortably accommodate groups of
                      6 in luxurious Lincoln Navigator SUVs and as many as 10
                      passengers in elegant stretch limousines.
                    </p>
                    <NavLink
                      className="button biggest"
                      to="/club-limousine-reservations"
                      target="_self">
                      BOOK NOW
                    </NavLink>
                  </div>
                </div>
                <div className="clearfix clearfix-height30"></div>
                <div className="row row-fluid padding-bottom20">
                  <h2 align="center" style={{ fontWeight: "700" }}>
                    Our{" "}
                    <img
                      src="/images/five-stars.png"
                      width="200"
                      height="118"
                    />{" "}
                    Reviews &amp; Testimonials
                  </h2>
                </div>
                <div
                  className="full_bg full-width-bg marine-header2-full_bg3"
                  data-animation="">
                  <h3
                    className="special-text wow animated fadeInDown marine-header2-h3"
                    data-animation="fadeInDown"
                    style={{ visibility: "visible", animationName: "none" }}>
                    Bay Area Luxury Transportation
                  </h3>
                  <div
                    className="special-text wow animated fadeInLeft marine-header2-special-text1"
                    data-animation="fadeInLeft"
                    style={{ visibility: "visible", animationName: "none" }}>
                    Black Car's East Bay limo deals are perfect for corporate
                    travel around town, including dining, event, and
                    entertainment venues.
                    <br />
                    Call CONTINENTAL CLUB CAR Luxury Transportation for all Your east
                    Bay area limo service needs.{" "}
                    <img
                      alt=""
                      className="aligncenter dont_scale wow animated fadeInDown"
                      data-animation="fadeInDown"
                      src="/images/la-limo-rentals.png"
                      style={{ visibility: "visible", animationName: "none" }}
                    />
                    <div className="clearfix"></div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </section>
      </div>
      <MainFooter />
    </>
  );
}

export default SonomaWineTour