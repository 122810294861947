/** @format */

import { Link, NavLink } from "react-router-dom";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import { useRef, useState } from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { MdImageNotSupported } from "react-icons/md";
import adminService from "../../services/adminService";
import { ReactToast, ReactToastSuccess } from "../../blocks/Modals/ReactToast";
import { BorderSpinner } from "../../blocks/Loaders/BorderSpinner";

export const AddCar = () => {
  const [myImages, setMyImages] = useState([]);
  const [oldImages, setOldImages] = useState([]);
  const formikRef = useRef();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  let getQuery = (key) => {
    return new URL(window.location.href).searchParams.get(key);
  };

  const ProfileSchema = Yup.object().shape({
    car_name: Yup.string().required("Please Enter car Name"),
    price_per_mile: Yup.string().required("Please Enter car price/mile"),
    passenger: Yup.string().required("Please Enter passenger"),
    luggage: Yup.string().required("Please Enter car luggage"),
  });

  let initial_Values = {
    car_name: getQuery("car_name") ? getQuery("car_name") : "",
    price_per_mile: getQuery("price_per_mile")
      ? getQuery("price_per_mile")
      : "",
    passenger: getQuery("passenger") ? getQuery("passenger") : 1,
    luggage: getQuery("luggage") ? getQuery("luggage") : 0,
    car_pics: [],
  };

  const handleSubmit = async (values) => {
    setLoading(true);
    let formData = new FormData();
    formData.append("car_name", values.car_name);
    formData.append("passenger", values.passenger);
    formData.append("price_per_mile", values.price_per_mile);
    formData.append("luggage", values.luggage);
    for (let i = 0; i < myImages.length; i++) {
      formData.append("car_pics[]", myImages[i], myImages[i].name);
    }
    if (getQuery("update") == "true" && getQuery("id")) {
      formData.append("id", getQuery("id"));
      formData.append("oldImages", JSON.stringify(oldImages));
      let res = await adminService.carUpdate(formData);
      if (res.status) {
        ReactToastSuccess(res.message);
        setLoading(false);
      } else {
        ReactToast("opps something went wrong Please try again");
        setLoading(false);
      }
    } else {
      let res = await adminService.carAdd(formData);
      if (res.status) {
        ReactToastSuccess(res.message);
        setLoading(false);
      } else {
        ReactToast("opps something went wrong Please try again");
        setLoading(false);
      }
    }
  };

  let removeImage = (e) => {
    let v = Object.values(myImages).filter((item) => item !== e);
    setMyImages(v);
  };

  let removeOldImage = (e) => {
      let v = oldImages.filter((item) => item !== e);
      setOldImages(v);
  }

  useEffect(() => {
    if (getQuery("car_pics")) {
      let img = JSON.parse(getQuery("car_pics"));
      console.log(img,"immmmmmmmm")
      setOldImages(img);
    }
  }, []);



  return (
    <>
      <div className="right_cntnt">
        <div className="d-flex sett_row">
          <div className="userSedit right_box">
            <div className="userEditForm">
              <Formik
                innerRef={formikRef}
                initialValues={initial_Values}
                enableReinitialize={true}
                validationSchema={ProfileSchema}
                onSubmit={(values) => {
                  handleSubmit(values);
                }}>
                {({ errors, touched, values }) => (
                  <Form>
                    <div className="row">
                      <div className="col-md-5 form-group with_210 margRt60">
                        <label className="form_lbl paddLft15">Car Title</label>
                        <Field
                          type="text"
                          name="car_name"
                          placeholder="UP TO 9 PAX VAN"
                          className={`form-control ${
                            touched.car_name && errors.car_name
                              ? "is-invalid"
                              : ""
                          }`}
                          autoComplete="off"
                        />
                        {errors.car_name && touched.car_name ? (
                          <div className="d-block invalid-feedback ">
                            {errors.car_name}
                          </div>
                        ) : null}
                      </div>
                      <div className="col-md-5 form-group with_210 margRt60">
                        <label className="form_lbl paddLft15">
                          Car Price (per/mile)
                        </label>
                        <Field
                          type="number"
                          name="price_per_mile"
                          placeholder="$ 140"
                          className={`form-control ${
                            touched.price_per_mile && errors.price_per_mile
                              ? "is-invalid"
                              : ""
                          }`}
                          autoComplete="off"
                        />
                        {errors.price_per_mile && touched.price_per_mile ? (
                          <div className="d-block invalid-feedback ">
                            {errors.price_per_mile}
                          </div>
                        ) : null}
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-5 form-group with_210 margRt60">
                        <label className="form_lbl">Number of Passengers</label>
                        <Field
                          type="number"
                          name="passenger"
                          placeholder="Enter Number of Passengers"
                          className={`form-control ${
                            touched.passenger && errors.passenger
                              ? "is-invalid"
                              : ""
                          }`}
                          autoComplete="off"
                        />
                        {errors.passenger && touched.passenger ? (
                          <div className="d-block invalid-feedback ">
                            {errors.passenger}
                          </div>
                        ) : null}
                      </div>
                      <div className="col-md-5 form-group with_210 margRt60">
                        <label className="form_lbl">Luggage Count</label>
                        <Field
                          type="number"
                          name="luggage"
                          placeholder="Enter Number of luggage"
                          className={`form-control ${
                            touched.luggage && errors.luggage
                              ? "is-invalid"
                              : ""
                          }`}
                          autoComplete="off"
                        />
                        {errors.luggage && touched.luggage ? (
                          <div className="d-block invalid-feedback ">
                            {errors.luggage}
                          </div>
                        ) : null}
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-3 form-group with_210 margRt60">
                        <label className="form_lbl paddLft15">
                          Profile photo
                        </label>
                        <div className="d-flex align-items-center">
                          <span className="profile_pic">
                            {/* <img src={`/images/user.png`} alt="img" /> */}
                          </span>
                          <div className="uploadFile">
                            <Field
                              type="file"
                              name="profilepic"
                              className="form-control"
                              multiple
                              onChange={(event) => {
                                setMyImages(event.target.files);
                              }}
                              autoComplete="off"
                            />
                            <label className="white_btn btn">
                              <img src="/images/img_upload.svg" alt="img" />
                              ADD CAR IMAGES
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div style={{ display: "flex" }}>
                      {myImages &&
                        Object.values(myImages).map((e, i) => (
                          <div
                            style={{
                              position: "relative",
                              width: "200px",
                              height: "200px",
                              margin: "10px",
                            }}>
                            <img
                              src={`${URL.createObjectURL(e)}`}
                              alt={i}
                              className="mr-3"
                              style={{
                                width: "100%",
                                "margin-right": "10px",
                              }}
                            />
                            <span
                              style={{
                                position: "absolute",
                                background: "black",
                                borderRadius: "40px",
                                top: 0,
                                right: 0,
                                color: "white",
                                zIndex: 99,
                                cursor: "pointer",
                              }}>
                              <MdImageNotSupported
                                size={20}
                                onClick={() => removeImage(e)}
                              />
                            </span>
                          </div>
                        ))}
                      {oldImages &&
                        oldImages.map((e, i) => (
                          <div
                            style={{
                              position: "relative",
                              width: "200px",
                              height: "200px",
                              margin: "10px",
                            }}>
                            <img
                              src={`${process.env.REACT_APP_BASE_URL}/Cars/${e}`}
                              alt={i}
                              className="mr-3"
                              style={{
                                width: "100%",
                                "margin-right": "10px",
                              }}
                            />
                            <span
                              style={{
                                position: "absolute",
                                background: "black",
                                borderRadius: "40px",
                                top: 0,
                                right: 0,
                                color: "white",
                                zIndex: 99,
                                cursor: "pointer",
                              }}>
                              <MdImageNotSupported
                                size={20}
                                onClick={() => removeOldImage(e)}
                              />
                            </span>
                          </div>
                        ))}
                    </div>
                    <div className="prifile_btn">
                      <button
                        type="submit"
                        className="btn save_btn"
                        style={{ backgroundColor: "#24356c", color: "white" }}>
                        {loading ? (
                          <BorderSpinner />
                        ) : (
                          <>
                            <img src="/images/save.svg" alt="img" />
                            ADD
                          </>
                        )}
                      </button>
                      <button type="button" className="btn cancel_btn">
                        <NavLink to="/admin-dashboard/cars">
                          <span className="close_icon">×</span> CANCEL
                        </NavLink>
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
