/** @format */

import { PostApi } from "./index";

class PaymentService {
  createPaymentIntent(data) {
    return PostApi(`/create-payment-intent`, data);
  }
}

const paymentService = new PaymentService();

export default paymentService;
