import React from 'react'
import MainFooter from '../../components/MainFooter';
import { MainHeader } from '../../components/MainHeader';
import { NavLink } from "react-router-dom";
const NycAndLi = () => {
  return (
    <>
      <MainHeader />
      <section id="main-content">
        <div className="container">
          <NavLink to="/reviews">
            <img
              className="rev_btn"
              src="/images/customer-reviews.png"
              width="100"
              height="39"
            />
          </NavLink>
          <div className="page-heading style3 wrapper border-bottom ">
            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-6">
                <h1>NYC and LI Car Service</h1>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-6">
                <p className="breadcrumbs">
                  <span typeof="v:Breadcrumb">
                    <NavLink to="/" property="v:title" rel="v:url">
                      Home
                    </NavLink>
                  </span>
                  <span className="delimiter">|</span>
                  <span className="current">Services</span>
                </p>
              </div>
            </div>
          </div>
          <div className="row padding-top60">
            <section className="col-lg-12 col-md-12 col-sm-12">
              <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="flexslider flexslider-thumbnail-gallery ">
                    <ul className="slides">
                      <li
                        className="flex-active-slide"
                        style={{
                          width: "100%",
                          float: "left",
                          marginRight: "-100%",
                          position: "relative",
                          opacity: 1,
                          display: "block",
                          zIndex: 2,
                        }}>
                        <img
                          src="/images/bay-area-car-service.jpeg"
                          draggable="false"
                          alt=""
                        />
                      </li>
                    </ul>
                    <ol className="flex-control-nav flex-control-thumbs"></ol>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <h2
                    className="special-text wow animated fadeInDown services-special-h3 animated"
                    data-animation="fadeInDown"
                    style={{
                      visibility: "visible",
                      animationName: "fadeInDown",
                    }}>
                    Superb NYC and LI Car Service
                  </h2>
                  <p>
                    Do you need a NYC and LI car transfer service you can count
                    on every time? Continental car club has the years of
                    experience that it takes to meet the demands of executive
                    corporate transportation. Our Nyc and li chauffeur service
                    delivers with skillful and respectful chauffeurs in flawless
                    vehicles for the best NYC and LI sedan services.
                  </p>
                  <p>
                    With a dedicated staff and experienced driver’s executives
                    our clients know that they can count on our NYC and LI sedan
                    service for airport transportation to get them to their
                    departures on time, relaxed, and with the opportunity to
                    conduct business or catch up on reading and email all along
                    the way. For arrivals we'll meet you at baggage claim and
                    whisk you past all the ground transportation confusion. Our
                    NYC and LI transfer service covers nearby JFK, LGA, and EWR
                    airports as well as private and municipal airports.
                  </p>
                  <p>
                    Whether airport, hotel, office, or a special event, our
                    private nyc and li car services provide convenient
                    door-to-door transportation. We'll keep you on schedule for
                    meetings, presentations, and conventions around town. Our
                    elegant NYC and LI sedan service is perfect for entertaining
                    out of town clients or associates with a night on the town,
                    or simply an impressive dinner then back to the hotel.
                  </p>
                  <NavLink
                    className="button biggest"
                    to="/club-limousine-reservations"
                    target="_self">
                    BOOK NOW
                  </NavLink>
                </div>
              </div>
              <div className="clearfix clearfix-height30"></div>
              <div className="row row-fluid padding-bottom20">
                <h2 align="center" style={{ fontWeight: "700" }}>
                  Our{" "}
                  <img src="/images/five-stars.png" width="200" height="118" />{" "}
                  Reviews &amp; Testimonials
                </h2>
              </div>
              <script
                src="https://apps.elfsight.com/p/platform.js"
                defer=""
                type="text/javascript"></script>
              <div
                className="full_bg full-width-bg marine-header2-full_bg3"
                data-animation="">
                <h3
                  className="special-text wow animated fadeInDown marine-header2-h3 animated"
                  data-animation="fadeInDown"
                  style={{
                    visibility: "visible",
                    animationName: "fadeInDown",
                  }}>
                  Nyc and Li Luxury Transportation
                </h3>
                <div
                  className="special-text wow animated fadeInLeft marine-header2-special-text1 animated"
                  data-animation="fadeInLeft"
                  style={{
                    visibility: "visible",
                    animationName: "fadeInDown",
                  }}>
                  For business and personal transportation, our Nyc and Li car
                  services get you where you need to be, right on schedule,
                  without stress, distractions, or parking delays. Choose a
                  luxury sedan, town car, or SUV and escape your daily pressures
                  with the comfort and amenities of our Nyc and Li car transfer
                  service.{" "}
                  <img
                    alt=""
                    className="aligncenter dont_scale wow animated fadeInDown animated"
                    data-animation="fadeInDown"
                    src="/images/la-limo-rentals.png"
                    style={{
                      visibility: "visible",
                      animationName: "fadeInDown",
                    }}
                  />
                  <div className="clearfix"></div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </section>
      <MainFooter />
    </>
  );
}

export default NycAndLi;