/** @format */

import React from "react";
import Home from "./pages/Home";
import { useRoutes, Navigate } from "react-router-dom";
import { MainLayout } from "./Layouts/MainLayout";
import NycAndLi from "./pages/services/NycAndLi";
import NycAndNorthJersey from "./pages/services/NycAndNorthJersey.js";
import { BayAreaCorporateTransportation } from "./pages/services/BayAreaCorporateTransportation";
import SouthBayCarService from "./pages/services/SouthBayCarService";
import { BayAreaLimousine } from "./pages/services/BayAreaLimousine";
import { SfoLimoCarTransfer } from "./pages/services/AirportService/SfoLimoCarTransfer";
import { OakLimoCarTransferService } from "./pages/services/AirportService/OakLimoCarTransferService";
import SjcLimoCarTransfer from "./pages/services/AirportService/SjcLimoCarTransfer";
import { Login } from "./Auth/Login";
import Register from "./Auth/Register";
import { NapaWineTour } from "./pages/services/WineTours/NapaWineTour";
import SonomaWineTour from "./pages/services/WineTours/SonomaWineTour";
import { ContactUs } from "./pages/ContactUs";
import { Fleet } from "./pages/Fleet";
import { FAQ } from "./pages/FAQ";
import { Dashboard } from "./UserDashboard/Dashboard";
import { AdminDashboard } from "./AdminDashboard/Pages/AdminDashboard";
import { useSelector } from "react-redux";
import BookVehicle from "./pages/Vehicle/BookVehicle";
import AdminWrapper from "./AdminDashboard/Layout/AdminWrapper";
import AdminLogin from "./AdminDashboard/Pages/AdminLogin";
import { AddCar } from "./AdminDashboard/Pages/AddCar";
import { Cars } from "./AdminDashboard/Pages/Cars";
import Reviews from "./pages/Reviews";
import CreateBooking from "./pages/Vehicle/CreateBooking";
import { Bookings } from "./AdminDashboard/Pages/Bookings";
import { VarifyMail } from "./Auth/VarifyMail";
const AllRoutes = () => {
  const { user } = useSelector((state) => state.auth);

  const ProtectedRoutes = ({ children }) => {
    return user ? children : <Navigate to="/login" />;
  };

  const ProtectedAdmin = ({ children }) => {
    return user?.user?.role == "admin" ? (
      children
    ) : (
      <Navigate to="/admin-login" />
    );
  };

  const element = useRoutes([
    { path: "/", element: <MainLayout /> },
    { path: "/login", element: <Login /> },
    { path: "/register", element: <Register /> },
    { path: "/verifyMail", element: <VarifyMail /> },
    { path: "/contactUs", element: <ContactUs /> },
    { path: "/services/nyc-li-car", element: <NycAndLi /> },
    { path: "/services/nyc-nj-limo", element: <NycAndNorthJersey /> },
    {
      path: "/services/nyc-corporate-transportation",
      element: <BayAreaCorporateTransportation />,
    },
    {
      path: "/services/south-bay-car-service",
      element: <SouthBayCarService />,
    },
    { path: "/services/bay-area-limousine", element: <BayAreaLimousine /> },
    //Airport service

    {
      path: "/service/nyc-li-limo-car-transfer",
      element: <SfoLimoCarTransfer />,
    },
    {
      path: "/service/ct-limo-car-transfer-service",
      element: <OakLimoCarTransferService />,
    },
    {
      path: "service/nj-limo-car-transfer-service",
      element: <SjcLimoCarTransfer />,
    },

    //wine tours
    { path: "/napa-wine-tours", element: <NapaWineTour /> },
    { path: "/sonoma-wine-tours", element: <SonomaWineTour /> },
    { path: "/bay-area-limousine-fleet", element: <Fleet /> },
    { path: "/faq", element: <FAQ /> },
    { path: "/reviews", element: <Reviews /> },
    {
      path: "/dashboard",
      element: (
        <ProtectedRoutes>
          <Dashboard />
        </ProtectedRoutes>
      ),
    },
    { path: "/club-limousine-reservations", element: <BookVehicle /> },
    { path: "/createbook", element: <CreateBooking /> },

    //Admin Module
    { path: "/admin-login", element: <AdminLogin /> },
    {
      path: "/admin-dashboard",
      element: (
        <ProtectedAdmin>
          <AdminWrapper />
        </ProtectedAdmin>
      ),
      children: [
        { path: "", element: <AdminDashboard /> },
        { path: "addCar", element: <AddCar /> },
        { path: "cars", element: <Cars /> },
        { path: "bookings", element: <Bookings /> },
      ],
    },
  ]);

  return element;
};

export default AllRoutes;
