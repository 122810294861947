import React from 'react'
import MainFooter from '../../../components/MainFooter';
import { MainHeader } from '../../../components/MainHeader';
import { NavLink } from "react-router-dom";

const SjcLimoCarTransfer = () => {
  return (
    <>
      <MainHeader />
      <div id="marine-content-inner">
        <section id="main-content">
          <div className="container">
            <NavLink href="https://www.continentalclubcar.com/reviews.html">
              <img
                className="rev_btn"
                src="/images/customer-reviews.png"
                width="100"
                height="39"
              />
            </NavLink>
            <div className="page-heading style3 wrapper border-bottom ">
              <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-6">
                  <h1>New Jersey Airport Transportation</h1>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6">
                  <p className="breadcrumbs">
                    <span typeof="v:Breadcrumb">
                      <NavLink to="/" property="v:title" rel="v:url">
                        Home
                      </NavLink>
                    </span>
                    <span className="delimiter">|</span>
                    <span className="current">Services</span>
                  </p>
                </div>
              </div>
            </div>
            <div className="row padding-top60">
              <section className="col-lg-12 col-md-12 col-sm-12">
                <div className="row">
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="flexslider flexslider-thumbnail-gallery ">
                      <ul className="slides">
                        <li
                          className="flex-active-slide"
                          style={{
                            width: "100%",
                            float: "left",
                            marginRight: "-100%",
                            position: "relative",
                            opacity: 1,
                            display: "block",
                            zIndex: "2",
                          }}>
                          <img
                            alt=""
                            src="/images/sjc-transportation.jpeg"
                            draggable="false"
                          />
                        </li>
                      </ul>
                      <ol className="flex-control-nav flex-control-thumbs"></ol>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <h2
                      className="special-text wow animated fadeInDown services-special-h3 animated"
                      data-animation="fadeInDown"
                      style={{
                        visibility: "visible",
                        animationName: "fadeInDown",
                      }}>
                      Superior NJ Car Services for Dependable Transportation
                    </h2>
                    <p>
                      New Jersey International is the gateway to the Silicon
                      Valley. For businesses that often means making the right
                      first impression with quality NJ airport limousine
                      service. Our NJ airport transfer service features
                      flawlessly maintained luxury sedans, town cars, SUVs, and
                      limousines coupled with experienced, well-trained, and
                      highly professional chauffeurs. We deliver excellence in
                      New Jersey Airport ground transportation. Whether for
                      business or personal travel, you can count on us for
                      impressive transportation experiences.
                    </p>
                    <h2
                      className="special-text wow animated fadeInDown services-special-h3 animated"
                      data-animation="fadeInDown"
                      style={{
                        visibility: "visible",
                        animationName: "fadeInDown",
                      }}>
                      NJ Car Service
                    </h2>
                    <p>
                      For departures our experienced NJ airport transfer
                      service staff and skilled drivers assure on-time drop off
                      at the right terminal. You'll arrive relaxed, having
                      avoided the complications of New Jersey Airport ground
                      transportation with no traffic, parking, or shuttle delay
                      worries, and with the time you need for baggage check in
                      and security at an international terminal.
                    </p>
                    <p>
                      When unusual itineraries or flight cancellations
                      complicate travel, our NJ airport transfer service
                      connects with SFO (San Francisco) International and OAK
                      (Oakland) International, both roughly an hour's drive
                      away. Our NJ car service and NJ airport limo service can
                      also connect with other local public and private airports.
                      You can count on CONTINENTAL CLUB CAR for dependable luxury in San
                      Jose Airport ground transportation for the South Bay. Your
                      NJ car service choices include BMW sedans, Lincoln town
                      cars and sedans, and Mercedes sedans as well as fully
                      equipped SUVs from Lincoln and Chevrolet.
                    </p>

                    <NavLink
                      className="button biggest"
                      href="/club-limousine-reservations"
                      target="_self">
                      BOOK NOW
                    </NavLink>
                  </div>
                </div>
                <div className="clearfix clearfix-height30"></div>
                <div className="row row-fluid padding-bottom20">
                  <h2 align="center" style={{ fontWeight: "700" }}>
                    Our{" "}
                    <img
                      src="/images/five-stars.png"
                      width="200"
                      height="118"
                    />{" "}
                    Reviews &amp; Testimonials
                  </h2>
                </div>
                <div
                  className="full_bg full-width-bg marine-header2-full_bg3"
                  data-animation="">
                  <h3
                    className="special-text wow animated fadeInDown marine-header2-h3"
                    data-animation="fadeInDown"
                    style={{
                      visibility: "visible",
                      animationName: "fadeInLeft",
                    }}>
                   New Jersey Luxury Transportation
                  </h3>
                  <div
                    className="special-text wow animated fadeInLeft marine-header2-special-text1"
                    data-animation="fadeInLeft"
                    style={{
                      visibility: "visible",
                      animationName: "fadeInLeft",
                    }}>
                    Black Car's East Bay limo deals are perfect for corporate
                    travel around town, including dining, event, and
                    entertainment venues.
                    <br />
                    Call CONTINENTAL CLUB CAR Luxury Transportation for all Your east
                    New Jersey service needs.{" "}
                    <img
                      alt=""
                      className="aligncenter dont_scale wow animated fadeInDown"
                      data-animation="fadeInDown"
                      src="/images/la-limo-rentals.png"
                      style={{
                        visibility: "visible",
                        animationName: "fadeInLeft",
                      }}
                    />
                    <div className="clearfix"></div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </section>
      </div>
      <MainFooter />
    </>
  );
}

export default SjcLimoCarTransfer