/** @format */

import React, { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

export const MainHeader = () => {
  let [ishomepage, setIsHomepage] = useState(false);
  let location = useLocation();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const open2 = Boolean(anchorEl2);
  const handleClick2 = (event) => {
    setAnchorEl2(event.currentTarget);
  };
  const handleClose2 = () => {
    setAnchorEl2(null);
  };


  useEffect(() => {
    if (location.pathname == "/") {
      setIsHomepage(true);
    }
  }, [location.pathname]);

  return (
    <>
      <header className="style4" id="header">
        <div id="upper-header">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12">
                <div className="item left hidden-separator">
                  <ul className="menu text-dark" id="menu-shop-header">
                    <li className="ml-4">Phone:</li>
                    <li className="menu-item">
                      <a
                        href="tel:1-718-961-1111"
                        className="nav-link text-dark"
                        style={{ fontSize: "20px", color: "white" }}>
                        1-718-961-1111
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="item right hidden-separator">
                  <ul className="menu text-dark" id="menu-shop-header">
                    <li className="">Email:</li>
                    <li className="menu-item">
                      <a
                        href="mailto:info@continentalclubcar.com"
                        className="nav-link text-dark main-email">
                        info@continentalclubcar.com
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="main-header">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12">
                <div id="main-nav-button">
                  <span></span> <span></span><span></span>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* mobile navlinks */}
        <div class="navbar" id="mobile-nav">
          <div class="navbar-container container">
            
            <input type="checkbox" name="" id="" />
            <div class="hamburger-lines">
              <span class="line line1"></span>
              <span class="line line2"></span>
              <span class="line line3"></span>
            </div>
            <img src="/images/Continental-Club-Car-Wordmark.png"
                  style={{
                    maxWidth: "230px",
                  }}
                className="pr-3"
            />

            <ul class="menu-items">
              <li className="mt-4">
                <NavLink to="/" className="nav-link text-dark mob-links">
                  Home
                </NavLink>
              </li>
              <li>
                <div>
                  <Button
                    id="demo-positioned-button"
                    className="text-dark mob-links"
                    aria-controls={open2 ? "demo-positioned-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open2 ? "true" : undefined}
                    onClick={handleClick2}>
                    Services
                  </Button>
                  <Menu
                    id="demo-positioned-menu"
                    aria-labelledby="demo-positioned-button"
                    anchorEl={anchorEl2}
                    open={open2}
                    onClose={handleClose2}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                    sx={{ zIndex: "9999" }}>
                    <MenuItem onClick={handleClose}>
                      <NavLink
                        to="/services/nyc-li-car"
                        onClick={() => {
                          window.scrollTo(0, 550);
                        }}>
                        New York Tri State Area Luxury Car
                      </NavLink>
                    </MenuItem>
                    <MenuItem onClick={handleClose}>
                      <NavLink
                        to="/services/nyc-nj-limo"
                        onClick={() => {
                          window.scrollTo(0, 550);
                        }}>
                        New York Tri State Area Luxury Suv
                      </NavLink>
                    </MenuItem>
                    <MenuItem onClick={handleClose}>
                      {" "}
                      <NavLink
                        to="/services/nyc-corporate-transportation"
                        onClick={() => {
                          window.scrollTo(0, 550);
                        }}>
                        New York Tri State Area Luxury Sprinter Van
                      </NavLink>
                    </MenuItem>
                  </Menu>
                </div>
              </li>
              <li>
                <div>
                  <Button
                    id="demo-positioned-button1"
                    className="text-dark mob-links"
                    aria-controls={open ? "demo-positioned-menu1" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                    onClick={handleClick}>
                    Airport service
                  </Button>
                  <Menu
                    id="demo-positioned-menu1"
                    aria-labelledby="demo-positioned-button1"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                    sx={{ zIndex: "9999" }}>
                    <MenuItem onClick={handleClose}>
                      <NavLink
                        to="/service/nyc-li-limo-car-transfer"
                        onClick={() => {
                          window.scrollTo(0, 550);
                        }}>
                        New York city and Long island (NYC & LI) Limo Car
                          Service
                      </NavLink>
                    </MenuItem>
                    <MenuItem onClick={handleClose}>
                      <NavLink
                        to="/service/nj-limo-car-transfer-service"
                        onClick={() => {
                          window.scrollTo(0, 550);
                        }}>
                        New York Tri State Area Luxury Suv
                      </NavLink>
                    </MenuItem>
                    <MenuItem onClick={handleClose}>
                      <NavLink
                        to="/club-limousine-reservations"
                        onClick={() => {
                          window.scrollTo(0, 550);
                        }}>
                         Connecticut (CT) Limo Car Service
                      </NavLink>
                    </MenuItem>
                  </Menu>
                </div>
              </li>
              <li>
                <NavLink
                  to="/bay-area-limousine-fleet"
                  className="nav-link"
                  onClick={() => {
                    window.scrollTo(0, 550);
                  }}>
                  <span className="nav-link text-dark">Fleet</span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/club-limousine-reservations"
                  className="nav-link"
                  onClick={() => {
                    window.scrollTo(0, 635);
                  }}>
                  <span
                    style={{ fontSize: "15px", textDecoration: "none" }}
                    className="nav-link text-dark">
                    <strong>Quote</strong>
                  </span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/club-limousine-reservations"
                  className="nav-link"
                  onClick={() => {
                    window.scrollTo(0, 635);
                  }}>
                  <span style={{ fontSize: "15px" }} className="text-dark">
                    <strong>Reserve</strong>
                  </span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/faq"
                  className="nav-link text-dark"
                  onClick={() => {
                    window.scrollTo(0, 550);
                  }}>
                  FAQ
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/contactUs"
                  className="nav-link text-dark"
                  onClick={() => {
                    window.scrollTo(0, 550);
                  }}>
                  Contact Us
                </NavLink>
              </li>
              {/* <li>
                <NavLink to="/reviews" className="nav-link text-dark">
                  Reviews
                </NavLink>
              </li> */}
              <li>
                <NavLink
                  to="/register"
                  className="nav-link text-dark"
                  onClick={() => {
                    window.scrollTo(0, 635);
                  }}>
                  Register
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/login"
                  className="nav-link text-dark"
                  onClick={() => {
                    window.scrollTo(0, 635);
                  }}>
                  Login
                </NavLink>
              </li>
            </ul>
          </div>
      
        </div>

        <div id="lower-header">
          <div className="container-fluid ">
            <div className="row">
              <div
                className="col-lg-12 col-md-12 col-sm-12"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}>
                <img
                  src="/images/Continental-Club-Car-Wordmark.png"
                  style={{
                    maxWidth: "200px",
                  }}
                  className="pr-3"
                />
                <ul className="menu " id="main-nav">
                  <li className="menu-item">
                    <NavLink to="/" className="nav-link text-dark">
                      Home
                    </NavLink>
                  </li>
                  <li className="menu-item menu-item-has-children">
                    <NavLink className="nav-link text-dark">Services</NavLink>
                    <ul className="sub-menu">
                      <li className="menu-item text-dark">
                        <NavLink
                          to="/services/nyc-li-car"
                          onClick={() => {
                            window.scrollTo(0, 550);
                          }}>
                          New York Tri State Area Luxury Car
                        </NavLink>
                      </li>
                      <li className="menu-item">
                        <NavLink
                          to="/services/nyc-nj-limo"
                          onClick={() => {
                            window.scrollTo(0, 550);
                          }}>
                          New York Tri State Area Luxury Suv
                        </NavLink>
                      </li>
                      <li className="menu-item">
                        <NavLink
                          to="/services/nyc-corporate-transportation"
                          onClick={() => {
                            window.scrollTo(0, 550);
                          }}>
                          New York Tri State Area Luxury Sprinter Van
                        </NavLink>
                      </li>
                    </ul>
                    <div className="dropdown-button"></div>
                  </li>
                  <li className="menu-item menu-item-has-children">
                    <span className="nav-link text-dark">Airport Service</span>
                    <ul className="sub-menu">
                      <li className="menu-item">
                        <NavLink
                          to="/service/nyc-li-limo-car-transfer"
                          onClick={() => {
                            window.scrollTo(0, 550);
                          }}>
                          New York city and Long island (NYC & LI) Limo Car
                          Service
                        </NavLink>
                      </li>
                      <li className="menu-item">
                        <NavLink
                          to="/service/ct-limo-car-transfer-service"
                          onClick={() => {
                            window.scrollTo(0, 550);
                          }}>
                          Connecticut (CT) Limo Car Service
                        </NavLink>
                      </li>
                      <li className="menu-item">
                        <NavLink
                          to="/service/nj-limo-car-transfer-service"
                          onClick={() => {
                            window.scrollTo(0, 550);
                          }}>
                          New Jersey (NJ) Limo Car Service
                        </NavLink>
                      </li>
                    </ul>
                    <div className="dropdown-button"></div>
                  </li>

                  <li className="menu-item">
                    <NavLink
                      to="/bay-area-limousine-fleet"
                      className="nav-link"
                      onClick={() => {
                        window.scrollTo(0, 550);
                      }}>
                      <span className="nav-link text-dark">Fleet</span>
                    </NavLink>
                  </li>
                  <li className="menu-item">
                    <NavLink
                      to="/club-limousine-reservations"
                      className="nav-link"
                      onClick={() => {
                        window.scrollTo(0, 635);
                      }}>
                      <span
                        style={{ fontSize: "15px", textDecoration: "none" }}
                        className="nav-link text-dark">
                        <strong>Quote</strong>
                      </span>
                    </NavLink>
                  </li>
                  <li className="menu-item">
                    <NavLink
                      to="/club-limousine-reservations"
                      className="nav-link"
                      onClick={() => {
                        window.scrollTo(0, 635);
                      }}>
                      <span style={{ fontSize: "15px" }} className="text-dark">
                        <strong>Reserve</strong>
                      </span>
                    </NavLink>
                  </li>
                  <li className="menu-item">
                    <NavLink
                      to="/faq"
                      className="nav-link text-dark"
                      onClick={() => {
                        window.scrollTo(0, 550);
                      }}>
                      FAQ
                    </NavLink>
                  </li>
                  <li className="menu-item">
                    <NavLink
                      to="/contactUs"
                      className="nav-link text-dark"
                      onClick={() => {
                        window.scrollTo(0, 550);
                      }}>
                      Contact Us
                    </NavLink>
                  </li>
                  {/* <li className="menu-item">
                    <NavLink to="/reviews" className="nav-link text-dark">
                      Reviews
                    </NavLink>
                  </li> */}
                  <li className="menu-item">
                    <NavLink
                      to="/register"
                      className="nav-link text-dark"
                      onClick={() => {
                        window.scrollTo(0, 635);
                      }}>
                      Register
                    </NavLink>
                  </li>
                  <li className="menu-item">
                    <NavLink
                      to="/login"
                      className="nav-link text-dark"
                      onClick={() => {
                        window.scrollTo(0, 635);
                      }}>
                      Login
                    </NavLink>
                  </li>
                </ul>
              </div>
            </div>
            {/* <nav class="navbar navbar-expand-lg">
              <NavLink class="navbar-brand" to="#">
                <img
                  className="logommain"
                  src="/images/Continental-Club-Car-Wordmark.png"
                  style={{ width: "200px" }}
                />
              </NavLink>
              <button
                class="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
              </button>

              <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav ms-auto">
                  <li class="nav-item">
                    <NavLink class="nav-link text-dark" to="/">
                      Home <span class="sr-only ">(current)</span>
                    </NavLink>
                  </li>
                  <li class="nav-item dropdown">
                    <Button
                      id="basic-button2"
                      className="text-dark"
                      aria-controls={open ? "basic-menu2" : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? "true" : undefined}
                      onClick={handleClick}
                      color="primary">
                      Services
                    </Button>
                    <Menu
                      id="basic-menu2"
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleClose}
                      MenuListProps={{
                        "aria-labelledby": "basic-button",
                      }}>
                      <MenuItem>
                        <NavLink
                          className="text-dark"
                          to="/services/nyc-li-car"
                          onClick={() => {
                            window.scrollTo(0, 550);
                          }}>
                          New York Tri State Area Luxury Car
                        </NavLink>
                      </MenuItem>
                      <MenuItem>
                        <NavLink
                          className="text-dark"
                          to="/services/nyc-nj-limo"
                          onClick={() => {
                            window.scrollTo(0, 550);
                          }}>
                          New York Tri State Area Luxury Suv
                        </NavLink>
                      </MenuItem>
                      <MenuItem>
                        <NavLink
                          className="text-dark"
                          to="/services/nyc-corporate-transportation"
                          onClick={() => {
                            window.scrollTo(0, 550);
                          }}>
                          New York Tri State Area Luxury Sprinter Van
                        </NavLink>
                      </MenuItem>
                    </Menu>
                  </li>
                  <li class="nav-item dropdown">
                    <Button
                      id="basic-button"
                      className="text-dark"
                      aria-controls={open ? "basic-menu" : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? "true" : undefined}
                      onClick={handleClick}
                      color="primary">
                      Airport Services
                    </Button>
                    <Menu
                      id="basic-menu"
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleClose}
                      MenuListProps={{
                        "aria-labelledby": "basic-button",
                      }}>
                      <MenuItem>
                        <NavLink
                          className="text-dark"
                          to="/services/nyc-li-car"
                          onClick={() => {
                            window.scrollTo(0, 550);
                          }}>
                          New York Tri State Area Luxury Car
                        </NavLink>
                      </MenuItem>
                      <MenuItem>
                        <NavLink
                          to="/service/nyc&li-limo-car-transfer"
                          onClick={() => {
                            window.scrollTo(0, 550);
                          }}>
                          New York city and Long island (NYC & LI) Limo Car
                          Service
                        </NavLink>
                      </MenuItem>
                      <MenuItem>
                        <NavLink
                          to="/service/ct-limo-car-transfer-service"
                          onClick={() => {
                            window.scrollTo(0, 550);
                          }}>
                          Connecticut (CT) Limo Car Service
                        </NavLink>
                      </MenuItem>
                      <MenuItem>
                        <NavLink
                          to="/service/nj-limo-car-transfer-service"
                          onClick={() => {
                            window.scrollTo(0, 550);
                          }}>
                          New Jersey (NJ) Limo Car Service
                        </NavLink>
                      </MenuItem>
                    </Menu>
                    <div
                      class="dropdown-menu"
                      aria-labelledby="navbarAirportMenuLink">
                      <NavLink
                        to="/service/nyc&li-limo-car-transfer"
                        onClick={() => {
                          window.scrollTo(0, 550);
                        }}>
                        New York city and Long island (NYC & LI) Limo Car
                        Transfer Service
                      </NavLink>
                      <NavLink
                        to="/service/ct-limo-car-transfer-service"
                        onClick={() => {
                          window.scrollTo(0, 550);
                        }}>
                        Connecticut (CT) Limo Car Transfer Service
                      </NavLink>
                      <NavLink
                        to="/service/nj-limo-car-transfer-service"
                        onClick={() => {
                          window.scrollTo(0, 550);
                        }}>
                        New Jersey (NJ) Limo Car Transfer Service
                      </NavLink>
                    </div>
                  </li>
                  <li className="nav-item">
                    <NavLink
                      to="/bay-area-limousine-fleet"
                      className="nav-link"
                      onClick={() => {
                        window.scrollTo(0, 550);
                      }}>
                      <span className="nav-link text-dark">Fleet</span>
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink
                      to="/club-limousine-reservations"
                      className="nav-link"
                      onClick={() => {
                        window.scrollTo(0, 635);
                      }}>
                      <span
                        style={{ fontSize: "15px", textDecoration: "none" }}
                        className="nav-link text-dark">
                        <strong>Quote</strong>
                      </span>
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink
                      to="/club-limousine-reservations"
                      className="nav-link"
                      onClick={() => {
                        window.scrollTo(0, 635);
                      }}>
                      <span style={{ fontSize: "15px" }} className="text-dark">
                        <strong>Reserve</strong>
                      </span>
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink
                      to="/faq"
                      className="nav-link text-dark"
                      onClick={() => {
                        window.scrollTo(0, 550);
                      }}>
                      FAQ
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink
                      to="/contactUs"
                      className="nav-link text-dark"
                      onClick={() => {
                        window.scrollTo(0, 550);
                      }}>
                      Contact Us
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink to="/reviews" className="nav-link text-dark">
                      Reviews
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink
                      to="/register"
                      className="nav-link text-dark"
                      onClick={() => {
                        window.scrollTo(0, 635);
                      }}>
                      Register
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink
                      to="/login"
                      className="nav-link text-dark"
                      onClick={() => {
                        window.scrollTo(0, 635);
                      }}>
                      Login
                    </NavLink>
                  </li>
                </ul>
              </div>
            </nav> */}
           </div>
        </div>
      </header>

      <div id="marine-content-wrapper">
        <img
          src={
            ishomepage
              ? "/images/continental-banner-1.jpg"
              : "/images/inner-banner.jpg"
          }
          alt="home pic"
          style={{ width: "100%" }}
          id="main-pic"
        />
      </div>
    </>
  );
};
