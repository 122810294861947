/** @format */

import React, { useState } from "react";
import MainFooter from "../components/MainFooter";
import { MainHeader } from "../components/MainHeader";

export const FAQ = () => {

  const [showAns,setShowAns] = useState("none")

  let faq = [
    {
      Q: `I booked a ride with the wrong pickup or dropoff location.What do I do?`,
      A: `For one way rides, if it is more than Two hour before your
        pickup time, you can simply change these locations in the
        app or on the website. You can make changes to hourly
        rides up to 24 hours before pickup time. If it is already
        less than two hours before your one way ride or you are
        already in the vehicle and require a new destination,
        please inform the driver about the route change. After
        your ride has ended, CONTINENTAL CLUB CAR will email you an
        Updated invoice with the new ride information. The invoice
        will show the price of the additional distance and drive
        time, if applicable. For any further questions, contact
        our Customer Care team.`,
    },
    {
      Q: `Are the prices shown per person or per vehicle?`,
      A: `The prices shown are always per vehicle and include all
        taxes, gratuities, and fees. The maximum number of
        passengers and bags, which can comfortably fit in each
        vehicle is shown under each car class. The number of
        passengers does not affect the price.`,
    },
    {
      Q: `How do I cancel my ride?`,
      A: `Log in to your CONTINENTAL CLUB CAR account and click on RIDES at
          the top of the page. Select the ride that you want to
          cancel and click CANCEL.`,
    },
    {
      Q: `Can I book a ride for someone else?`,
      A: `Yes! If you have a CONTINENTAL CLUB CAR account, you can book a
          ride for someone else. During the booking process, please
          provide the name, email address, and mobile number of the
          person you are booking for to ensure that the passenger
          also gets the email and text message notifications on the
          day of the ride.`,
    },
    {
      Q: `How do I make changes to my booking?`,
      A: `You can change the car class, pickup date, time and
          location, dropoff location, and additional information.
          Log in to your CONTINENTAL CLUB CAR account on the website, click
          on RIDES at the top of the page and select the ride that
          you would like to make changes to. You will then see a
          page with the details of your ride, you can make changes
          to your ride on this page. To make changes to your ride in
          the app, select Help and call for support or send feedback
          to update your upcoming reservation For one-way rides you
          can make changes up to Seventy Two hours before the pickup
          time and for hourly rides you can make changes up to 72
          hours before the pickup time.`,
    },
    {
      Q: `Do I need to pay if I cancel the ride booking after I’ve already received a Conformation Email?`,
      A: `For one-way rides, if you receive a Conformation Email and
          it is more than Seventy Two hours before your pickup time
          for Sedan and Suv's and 2 week for Sprinter, mini coach
          van and stretch limo, you may still cancel free of charge.
          If you cancel less than Seventy Two hours before the
          pickup time for Sedan and SUV's and 2 week for Sprinter,
          mini coach van and stretch limo you will be charged the
          full amount for the ride. Hourly rides can be cancelled 72
          hours before the pickup time free of charge for only Sedan
          and SUV's and 2 week for Sprinter, mini coach van and
          stretch limo. All cancellations can only be made using the
          cancel feature in our Apps or Website.`,
    },
  ];

  let showQues = (i) => {
    document.getElementsByClassName("accordion-content")[i].style.display = "block"
  }

  return (
    <>
      <MainHeader />
      <div class="container">
        <a href="https://www.continentalclubcar.com/reviews">
          <img
            class="rev_btn"
            src="/images/customer-reviews.png"
            width="100"
            height="39"
          />
        </a>
        <div class="page-heading style3 wrapper border-bottom ">
          <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-6">
              <h1>Frequestnly Asked Questions</h1>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6">
              <p class="breadcrumbs">
                <span typeof="v:Breadcrumb">
                  <a href="/" property="v:title" rel="v:url">
                    Home
                  </a>
                </span>
                <span class="delimiter">|</span>
                <span class="current">FAQ</span>
              </p>
            </div>
          </div>
        </div>
        <div class="clearfix clearfix-height35"></div>
        <div class="container">
          <h2>WHAT CAN WE DO FOR YOU?</h2>
          <h4>
            Below you will find answers to some of our most Frequently Asked
            Questions. Simply choose from the categories below to quickly find
            an answer
          </h4>
          <div class="clearfix clearfix-height35"></div>
          <div class="row">
            <section class="main-content col-lg-12 col-md-12 col-sm-12 small-padding">
              <div class="accordions faq-accordions" id="MixItUp19049B">
                {/* faq start here */}

                {faq ? faq.map((e, i) => (
                      <>
                        <div class="accordion mix faq-pricing faq-support"
                          style={{ display: "block" }}>
                          <div class="accordion-header">
                            <div
                              class="accordion-icon"
                              onClick={() => showQues(i)}></div>
                            <h5>{e.Q}</h5>
                          </div>
                          <div class="accordion-content" style={{ display: "none" }}>
                            <p>{e.A}</p>
                          </div>
                        </div>
                      </>
                    ))
                  : "No FAQ Found!"}
              </div>
              <div class="row row-fluid padding-bottom20">
                <h2 align="center" style={{ fontWeight: "700" }}>
                  Our
                  <img
                    src="/images/five-stars.png"
                    width="200"
                    height="118"
                  />{" "}
                  Reviews &amp; Testimonials
                </h2>
              </div>
            </section>
          </div>
        </div>
      </div>
      <MainFooter />
    </>
  );
};
