/** @format */

import React, { useEffect, useRef } from "react";
import MainFooter from "../components/MainFooter";
import { MainHeader } from "../components/MainHeader";
import { NavLink, useNavigate } from "react-router-dom";
import { ReactToast } from "../blocks/Modals/ReactToast";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { clearError, requestToLogin } from "./authSlice";
import { useDispatch, useSelector } from "react-redux";
import { BorderSpinner } from "../blocks/Loaders/BorderSpinner";

export const Login = (props) => {
  const { user, error, status } = useSelector((state) => state.auth);

  const formikRef = useRef();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const LoginSchema = Yup.object().shape({
    email: Yup.string().email().required("Enter Your Email"),
    password: Yup.string().required("Enter The Password"),
  });

  const values = {
    email: "",
    password: "",
  };


  // let CheckOutGuest = () =>{

  // }

  const handleSubmit = (values) => {
    dispatch(requestToLogin(values));
  };

  useEffect(() => {
    if (error) {
      ReactToast(error);
      dispatch(clearError());
    }
  }, [error]);

  useEffect(() => {
    if (user == null) {
      if (props.fake) {
        return;
      } else {
        navigate("/login");
      }
    } else {
      navigate("/dashboard");
    }
  }, [user]);
  return (
    <>
      {props.fake ? (
        <></>
      ) : (
        <>
          <MainHeader />
          <div className="container mt-5 mb-5">
            <div className="page-heading style3 wrapper border-bottom ">
              <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-6">
                  <h1>Bay Area Limousine Reservations</h1>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6">
                  <p className="breadcrumbs">
                    <span typeof="v:Breadcrumb">
                      <NavLink to="/">Home</NavLink>
                    </span>
                    <span className="delimiter">|</span>
                    <span className="current">Limo Quote</span>
                  </p>
                </div>
              </div>
            </div>
          </div>{" "}
        </>
      )}
      <>
        <div className="container find-vehicle-container">
          {props.fake ? (
            <></>
          ) : (
            <header id="top" className="ors-menu-top">
              <nav className="navbar">
                <div
                  className="container-fluid mt-3"
                  id="accountInformationHeader">
                  <ul className="nav navbar-nav pull-left">
                    <li>
                      <NavLink to="/club-limousine-reservations">New Reservation</NavLink>
                    </li>
                  </ul>
                </div>
              </nav>
            </header>
          )}

          <div className="row">
            <div className="col-md-6 p-5">
              <div className="container">
                <div className="row ride-info">
                  <span className="panel-title ml-5">
                    <span>Log in</span>
                  </span>
                </div>
              </div>
              <div className="ors-col-max-width mt-4">
                <Formik
                  innerRef={formikRef}
                  enableReinitialize={true}
                  initialValues={values}
                  validationSchema={LoginSchema}
                  onSubmit={(values) => {
                    handleSubmit(values);
                  }}>
                  {({ errors, touched }) => (
                    <Form className="login_form position-relative">
                      <div className="form-group form-group-lg">
                        <label for="username-login" className="label-lg">
                          Email Address / Username
                        </label>
                        <Field
                          type="text"
                          id="username-login"
                          name="email"
                          tabindex="4"
                          autofocus=""
                          autocomplete="email"
                          className={`The-form-select  ${touched.email && errors.email
                              ? "is-invalid form-control"
                              : ""
                            }`}
                        />
                        {errors.email && touched.email ? (
                          <div className="d-block invalid-feedback ">
                            {errors.email}
                          </div>
                        ) : null}
                        <div
                          className="text-danger"
                          style={{ display: "none" }}
                          id="username-login-required-message">
                          Username is required
                        </div>
                      </div>
                      <div className="form-group form-group-lg mt-4">
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}>
                          <label for="password-login" className="label-lg">
                            Password
                          </label>
                          <div className="pull-right">
                            <NavLink
                              to="/v4/sflimollc/Account/ForgotPassword?Length=7"
                              id="forgotPassLink">
                              Forgot password?
                            </NavLink>
                          </div>
                        </div>
                        <Field
                          type="password"
                          id="password-login"
                          name="password"
                          tabindex="5"
                          autocomplete="current-password"
                          className={`The-form-select  ${touched.password && errors.password
                              ? "is-invalid form-control"
                              : ""
                            }`}
                        />
                        {errors.password && touched.password ? (
                          <div className="d-block invalid-feedback ">
                            {errors.password}
                          </div>
                        ) : null}
                        <div className="form-group mt-4">
                          <button type="submit" style={{ width: "100%" }}>
                            {status == "loading" ? <BorderSpinner /> : "Log In"}
                          </button>
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
              <div className="text-center mt-3">
                Don’t have an account?
                <NavLink to="/register"> Register Now</NavLink>
              </div>
            </div>
            <div className="col-md-6 p-5 mb-4">
              <div className="container">
                <div className="row ride-info">
                  <span className="panel-title ml-5">
                    <span>Why should I log in?</span>
                  </span>
                </div>
              </div>
              <div className="ors-col-max-width mt-5">
                <ul className="ors-list-default">
                  <li>Book rides even faster using stored account details.</li>
                  <li>Modify trip details.</li>
                  <li>Access invoices and payment receipts.</li>
                  <li>Reporting tools.</li>
                </ul>
              </div>
              {/* <div className="text-center mt-4">
                <button className="mt-5 p-2" onClick={()=>CheckOutGuest()} >Checkout as Guest</button>
              </div> */}
            </div>
          </div>
        </div>
        {props.fake ? (
          <></>
        ) : (
          <div className="row row-fluid padding-bottom20 mt-2">
            <h2 align="center" style={{ fontWeight: "700" }}>
              Our <img src="/images/five-stars.png" width="200" height="118" />{" "}
              Reviews &amp; Testimonials
            </h2>
          </div>
        )}
      </>
      {props.fake ? <></> : <MainFooter />}
    </>
  );
};
